export const routes = {
  login: '/login',
  authentication: '/auth',
  oauth2: `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=C0c75ea43de4ccef4ed76c37acca783a9787eb584&response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}v1%2Fauth%2Fsso&scope=openid%20profile%20email`,
  home: '/',
  positions: {
    view: '/vacantes',
    new: '/vacantes/nueva',
    edit: '/vacantes/editar',
    detail: '/vacantes/detalle',
  },
  users: '/usuarios',
  settings: {
    home: '/configuracion',
    categories: '/configuracion/categorias',
    stores: '/configuracion/tiendas',
    teams: '/configuracion/equipos',
    schedules: '/configuracion/horarios',
    studyLevels: '/configuracion/niveles-de-estudio',
    languages: '/configuracion/idiomas',
    referrals: '/configuracion/convenios',
    sports: '/configuracion/deportes',
    events: '/configuracion/eventos-de-aplicacion',
  },
  contents: {
    home: '/contenidos',
    aboutUs: '/contenidos/quienes-somos',
    ourTeam: '/contenidos/nuestro-equipo',
    positions: '/contenidos/vacantes'
  },
  youtube: 'https://www.youtube-nocookie.com/embed/'
};