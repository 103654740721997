import request from './requestWrapper';

// Types
import {SubscriptionType} from '../types/subscriptionTypes';
import {GetPositionsType} from '../types/positionTypes';

// #region GET

function getAllByPositionId(positionId: string) {
  return request({
    url: `placePositions/getAllByPositionId/${positionId}`,
    method: 'GET',
  });
}

// #region POST

function getAllPositionsSubscribed(data: GetPositionsType) {
  return request({
    url: 'placePositions/getAllPositionsSubscribed',
    method: 'POST',
    data: data
  });
}
function createAll(data: SubscriptionType[]) {
  return request({
    url: 'placePositions/createAll',
    method: 'POST',
    data: data
  });
}

// #endregion

// #region DELETE

function deleteSubscription(positionId: string) {
  return request({
    url: `placePositions/delete/${positionId}`,
    method: 'DELETE',
  });
}

// #endregion

export default {
  getAllPositionsSubscribed, getAllByPositionId,
  createAll,
  deleteSubscription,
}