import request from './requestWrapper';

// Types
import { AboutUsContentType, OurTeamContentType, PositionContentType } from '../types/contentTypes';

// #region GET

function getAboutUsContent() {
  return request({
    url: 'aboutUsContent/getAboutUsContent',
    method: 'GET',
  });
}
function getOurTeamContent() {
  return request({
    url: 'ourTeamContent/getOurTeamContent',
    method: 'GET',
  });
}
function getPositionContent() {
  return request({
    url: 'positionContent/getPositionContent',
    method: 'GET',
  });
}

// #endregion

// #region POST

function setAboutUsContent(data: AboutUsContentType) {
  return request({
    url: 'aboutUsContent/setAboutUsContent',
    method: 'POST',
    data: data
  });
}
function setOurTeamContent(data: OurTeamContentType) {
  return request({
    url: 'ourTeamContent/setOurTeamContent',
    method: 'POST',
    data: data
  });
}
function setPositionContent(data: PositionContentType) {
  return request({
    url: 'positionContent/setPositionContent',
    method: 'POST',
    data: data
  });
}

// #endregion

export default {
  getAboutUsContent, getOurTeamContent, getPositionContent, 
  setAboutUsContent, setOurTeamContent, setPositionContent 
}