import request from './requestWrapper';

// Types
import {GetPositionsType, PositionType, SharePositionsType} from '../types/positionTypes';

// #region GET

function getParams() {
  return request({
    url: 'positions/getParams',
    method: 'GET',
  });
}
function getPositionById(positionId: string) {
  return request({
    url: `positions/getById/${positionId}`,
    method: 'GET',
  });
}

// #region POST

function getAll(data: GetPositionsType) {
  return request({
    url: 'positions/getAll',
    method: 'POST',
    data: data
  });
}
function addPositionImage(data: FormData) {  
  return request({
    url: 'positions/addImage',
    method: 'POST',
    data: data
  });
}
function add(data: PositionType) {
  return request({
    url: 'positions/create',
    method: 'POST',
    data: data
  });
}
function createMassiveExEmployees(data: FormData) {
  return request({
    url: 'ex-employees/createMassive',
    method: 'POST',
    data: data
  });
}
function sharePosition(data: SharePositionsType) {
  return request({
    url: 'positions/sharePosition',
    method: 'POST',
    data: data
  });
}

// #endregion

// #region PUT

function update(data: PositionType) {
  return request({
    url: 'positions/update',
    method: 'PUT',
    data: data
  });
}

// #endregion

// #region DELETE

function deletePosition(positionId: string) {
  return request({
    url: `positions/delete/${positionId}`,
    method: 'DELETE',
  });
}

// #endregion

export default {
  getParams, getAll, getPositionById,
  addPositionImage, add, createMassiveExEmployees, sharePosition, 
  update,
  deletePosition
}