// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import {Card, Col, Row, Empty} from 'antd';
import {StarFilled} from '@ant-design/icons';

// Store
import * as  applicationsSelector from '../../../../store/redux/selectors/applicationSelectors';

// Components and Subcomponents
import TitleComponent from '../../../subComponents/TitleComponent';


const StudiesSkillsInfo = () => {
  const applicationDetail = useSelector(applicationsSelector.applicationsDetailSelector);
  const formation = applicationDetail.formation;
  const languages = applicationDetail.languages;
  const sports = applicationDetail.sports;
  
  return (
    <Col className={'mb-2 bg-white p-4 rounded'}>
      <Col md={24} xs={24} className={'[&>div]:px-1.5 [&>div]:inline-block [&>div]:w-full'}>
        <Col md={24} xs={24}>
          <Row>
            <Col md={24} xs={24}>
              <TitleComponent level={5} text={'Estudios:'} className={'text-sm font-medium text-grey-dkt-400'} />
            </Col>
            {formation.length > 0 ?
              formation.map(grade =>
                <Col key={grade.idFormationsApplicant} md={24} sm={12} xs={24} className={'mb-3'}>
                  <Card key={grade.idFormationsApplicant} bordered={false} className={'[&>div]:p-0'}>
                    <p className={'font-medium'}>{grade.formation}</p>
                    <p>{grade.title}</p>
                    <i>{grade.institute}</i>
                  </Card>
                </Col>
              )
            :
              <Empty description={'Sin estudios registrados'}/>
            }
          </Row>
        </Col>
        <Col md={24} xs={24}>
          <Row>
            <Col md={24} xs={24}>
              <TitleComponent level={5} text={'Idiomas:'} className={'text-sm font-medium text-grey-dkt-400'} />
            </Col>
            {languages.length > 0 ?
              languages.map(language =>
                <Col key={language.idLanguageApplicant} md={24} sm={12} xs={24} className={'mb-3'}>
                  <Card key={language.idLanguageApplicant} bordered={false} className={'[&>div]:p-0 [&>div]:pr-6'}>
                    <p className={'font-medium'}>{language.language.name}</p>
                    <Col md={24} xs={24}>
                      <span>Habla</span>
                      <span className={'float-right'}>
                        <StarFilled className={'[&>svg]:fill-yellow-dkt-400'} /> {language.speaking}/5
                      </span>
                    </Col>
                    <Col md={24} xs={24}>
                      <span>Escucha</span>
                      <span className={'float-right'}>
                        <StarFilled className={'[&>svg]:fill-yellow-dkt-400'} /> {language.listening}/5
                        </span>
                    </Col>
                    <Col md={24} xs={24}>
                      <span>Lee</span>
                      <span className={'float-right'}>
                        <StarFilled className={'[&>svg]:fill-yellow-dkt-400'} /> {language.reading}/5
                        </span>
                    </Col>
                    <Col md={24} xs={24}>
                      <span>Escribe</span>
                      <span className={'float-right'}>
                        <StarFilled className={'[&>svg]:fill-yellow-dkt-400'} /> {language.writing}/5
                      </span>
                    </Col>
                  </Card>
                </Col>
              )
            :
              <Empty description={'Sin idiomas registrados'}/>
            }
          </Row>  
        </Col>     
        <Col md={24} xs={24}>
          <Row>
            <Col md={24} xs={24}>
              <TitleComponent level={5} text={'Deportes:'} className={'text-sm font-medium text-grey-dkt-400'} />
            </Col>
            {sports.length > 0 ?
              sports.map(sport =>
                <Col key={sport.idSport} md={24} sm={12} xs={24} className={'mb-3'}>
                  <Card key={sport.idSport} bordered={false} className={'[&>div]:p-0'}>
                    <p className={'font-medium'}>{sport.sport.name}</p>
                  </Card>
                </Col>
              )
            :
              <Empty description={'Sin deportes registrados'}/>
            }
          </Row>
        </Col>
      </Col>
    </Col>
  );
}

export default StudiesSkillsInfo;