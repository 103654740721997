import {StoreType} from '../../../types/storeTypes';

export const aboutUsSelector = (state: StoreType) => {
  return state.contentsReducer.aboutUsContent;
}
export const ourTeamSelector = (state: StoreType) => {
  return state.contentsReducer.ourTeamContent;
}
export const positionSelector = (state: StoreType) => {
  return state.contentsReducer.positionContent;
}