// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Col, List, Card, Space, Empty, Tag} from 'antd';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Types
import {ApplicationFiltersType, ApplicationType} from '../../../types/applicationsTypes';

// Functions and Constants
import {getDateDifference, screenSizeValidator} from '../../../utils/functions';
import {hoverableCardClassName, hoverableCardContentClassName} from '../../../utils/componentsUtils';

// Components
import ApplicantDetailFilters from '../filters/ApplicantDetailFilters';
import ApplicantDetailModal from './ApplicantDetailModal';


const ApplicantList = () => {
  const [applicantModalVisible, setApplicantModalVisible] = useState(false);
  const [idApplications, setIdApplications] = useState('');
  const [applicationsFiltered, setApplicationsFiltered] = useState<ApplicationType[]>([]);
  const applications = useSelector(positionSelectors.positionApplicationsSelector) ?? [];
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const isXS = screenSizeValidator('(max-width: 575px)');
  const isXL = screenSizeValidator('(min-width: 1200px)');

  useEffect(() => {
    setApplicationsFiltered(applications);
  }, [applications]);

  const onToggleModal = (isApplicantModalVisible: boolean, idApplications?: string) => {
    setApplicantModalVisible(isApplicantModalVisible);
    setIdApplications(idApplications ?? '');
  }
  
  const handleApplicantFilters = (filters?: ApplicationFiltersType) => {
    const applicationsFiltered = 
      applications.filter(application => 
        (!filters?.idReferred || (application.applicant?.idReferred === filters?.idReferred)) &&
        (!filters?.idSports || (application.applicant?.sports?.find(idSport => idSport === filters?.idSports))) &&
        (!filters?.idEvent || (application.idLastEvent === filters?.idEvent))
      )
    setApplicationsFiltered(applicationsFiltered);
  } 

  return (
    applications.length > 0
      ?
      <Col md={24} xs={24}>
        <Col md={24} xs={24} className={'mb-4'}>
          <ApplicantDetailFilters onChangeFilters={handleApplicantFilters} />
        </Col>

        <List dataSource={applicationsFiltered}
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4 }}
              pagination={{
                size: 'small',
                pageSize: isXS ? 3 : isXL ? 8 : 6,
                hideOnSinglePage: true
              }}
              renderItem={(application) => (
                <List.Item>
                  <Card hoverable={true} className={hoverableCardClassName()}
                        onClick={() => onToggleModal(true, application.idApplications)}>
                    <Row className={hoverableCardContentClassName()}>
                      <Space direction={'vertical'} size={'middle'} className={'w-full'}>   
                        <Col md={24} xs={24} className={'text-right'}>
                          <Tag color="blue" className={'rounded'}>
                            {positionParams?.placeOptions.find(placeOption => 
                              placeOption.id === application.placePosition?.idPlace)?.value
                            }
                          </Tag>
                        </Col>                   
                        <Col md={24} xs={24} className={'text-center text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                          <b className={'text-grey-dkt-700 leading-none'} title={'Nombre Apellido'}>
                            {application.applicant!.firstName} {application.applicant!.lastName}
                          </b>
                          
                          <p className={'m-0 text-grey-dkt-400 leading-none italic'}>
                            Se postuló hace {getDateDifference(application.timeStamp)}
                          </p>
                        </Col>
                        <Col md={24} xs={24} className={'text-xs text-grey-dkt-600 text-center bg-grey-dkt-50 p-4 rounded'}>
                          <Col md={24} xs={24} className={'text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                            <MailOutlined className={'mr-2'} />
                            <span title={application.applicant!.email}>{application.applicant!.email}</span>
                          </Col>
                          <Col md={24} xs={24} className={'text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                            <PhoneOutlined className={'mr-2'} />
                            <span title={application.applicant!.phone}>{application.applicant!.phone}</span>
                          </Col>
                        </Col>
                      </Space>
                    </Row>
                  </Card>
                </List.Item>
              )}
        />

        {applicantModalVisible &&
          <ApplicantDetailModal idApplications={idApplications} onToggleModal={setApplicantModalVisible} />
        }
      </Col>
      :
      <Empty description={'No hay candidatos'} />
  );
}

export default ApplicantList;