// Libraries
import React, {FunctionComponent} from 'react';
import { useSelector } from 'react-redux';
import {Col, Form, Modal, Row} from 'antd';
import PropTypes from 'prop-types';

// Store
import { useAppDispatch } from '../../../store/store';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';
import { sharePosition } from '../../../store/redux/actions/positionActions';

// Types
import { SharePositionsType } from '../../../types/positionTypes';

// Components and Subcomponents
import ButtonComponent from '../../subComponents/ButtonComponent';
import InputComponent from '../../subComponents/InputComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';


type SharePositionModalType = {
  onToggleModal: (visible: boolean) => void;
};
const SharePositionModal: FunctionComponent<SharePositionModalType> = (
  {
    onToggleModal
  }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const positionDetail = useSelector(positionSelectors.positionDetailSelector);

  const handleSubmit = (values: SharePositionsType) => {
    dispatch(sharePosition(values));
    onToggleModal(false)
  }

  return (
    <Modal title={'Compartir vacante'} visible={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           onCancel={() => onToggleModal(false)}
           footer={[
            <ButtonComponent key={'cancel'} text={'Cancelar'}
                            className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                              'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                            onClick={() => onToggleModal(false)} />,
            <ButtonComponent key={'submit'} text={'Enviar'} htmlType={'submit'}
                            className={'w-32 h-8 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none text-white'}
                            onClick={() => form.submit()} />
           ]}>
          <Form form={form} initialValues={{idPosition: positionDetail?.idPosition}} 
                layout="vertical" autoComplete="off" onFinish={handleSubmit}>
            <Row>
              <FormItemComponent name={'idPosition'} required={false} child={<></>} />     
              <Col md={24} xs={24} className={'w-full'}> 
                <FormItemComponent name={'destinationEmail'} label={'Correo electrónico'} required={true} className={'w-full'}
                                   newRules={[{type:'email', message: 'No es un correo válido'}]}
                                   child={
                                    <InputComponent type={'email'} />
                                   } />
              </Col>
            </Row>
          </Form>
        
    </Modal>
  );
}

SharePositionModal.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
SharePositionModal.defaultProps = {
  onToggleModal: () => {
    return null;
  }
};

export default SharePositionModal;