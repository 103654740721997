import {StoreType} from '../../../types/storeTypes';

export const userParamsSelector = (state: StoreType) => {
  return state.userReducer.userParams;
}

export const allUsersSelector = (state: StoreType) => {
  return state.userReducer.userList;
}

export const userInfoSelector = (state: StoreType) => {
  return state.userReducer.userInfo;
}