// Store
import {AppDispatch} from '../../store';

// Services
import authServices from '../../../api/authServices';

// Components and Subcomponents
import {authTypes} from '../types';


// #region GET

export const getSessionState = () => async (dispatch: AppDispatch) => {
  authServices.getSessionState()
    .then((response) => {
      dispatch({
        type: authTypes.SIGN_OFF,
        signOff: response.data.signOff
      });
    })
};

// #endregion