// Constants
import {UserReducerType} from '../../../types/storeTypes';
import {userTypes} from '../types';

const initialState = {
  userList: [],
  userParams: {
    placeOptions: [],
    roleTypes: [],
    teamOptions: []
  }
}

const userReducer = (state = initialState, action: UserReducerType) => {
  switch (action.type) {
    case userTypes.GET_USER_PARAMS:
      return {
        ...state,
        userParams: action.userParams
      };
    case userTypes.GET_ALL_USERS:
      return {
        ...state,
        userList: action.userList
      };
    case userTypes.GET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      };
    default:
      return state;
  }
};

export default userReducer;
