import React from 'react';
import {Row, Col} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';

// Components and Subcomponents
import TitleComponent from '../../subComponents/TitleComponent';
import PositionForm from './PositionForm';

// Functions and Constants
import {redirectWindow} from '../../../utils/functions';
import {routes} from '../../../utils/routes';

const NewPosition = () => {
  const handleReturnPositions = () => {
    redirectWindow(routes.positions.view);
  };
  
  return (
    <Row>
      <Col md={24} xs={24}>
        <span className={'mb-2 p-0 cursor-pointer text-grey-dkt-500 font-medium text-sm hover:bg-[transparent]'}
              onClick={handleReturnPositions}>
          <ArrowLeftOutlined /> Vacantes
        </span>
      </Col>

      <Col md={24} xs={24}>
        <TitleComponent level={3} text={'Nueva vacante'} className={'font-semibold text-gray-900'} />
      </Col>
      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <PositionForm isEditing={false} />
      </Col>
    </Row>
  );
}

export default NewPosition;