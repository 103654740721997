import { Modal } from 'antd';
import {store} from '../../store/store';


export function SUCCESS_MODAL(message: string) {
  Modal.success({
    title: 'Acción realizada correctamente',
    content: message,
    keyboard: false,
    maskClosable: false,
  });
}
export function SUCCESS_MODAL_ON_OK(message: string, onOkFunction: () => void) {
  Modal.success({
    title: 'Acción realizada correctamente',
    content: message,
    keyboard: false,
    maskClosable: false,
    onOk () {
      store.dispatch(onOkFunction)
    }
  });
}
export function ERROR_MODAL(message: string) {
  Modal.error({
    title: 'Error al realizar la acción',
    content: message,
    keyboard: false,
    maskClosable: false
  });
}

export function WARNING_MODAL(message: string) {
  Modal.warning({
    title: '¡Advertencia!',
    content: message,
    keyboard: false,
    maskClosable: false
  });
}

export function WARNING_MODAL_ON_OK(message: string, onOkFunction: () => void) {
  Modal.warning({
    title: '¡Advertencia!',
    content: message,
    keyboard: false,
    maskClosable: false,
    onOk () {
      store.dispatch(onOkFunction)
    },
    onCancel () {
      store.dispatch(onOkFunction)
    }
  });
}