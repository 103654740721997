// Constants
import {AuthReducerType} from '../../../types/storeTypes';
import {authTypes} from '../types';

const initialState = {
  signOff: false,
}

const authReducer = (state = initialState, action: AuthReducerType) => {
  switch (action.type) {
    case authTypes.SIGN_OFF:
      return {
        ...state,
        signOff: action.signOff
      };
    default:
      return state;
  }
};

export default authReducer;
