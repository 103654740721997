// Constants
import {SubscriptionReducerType} from '../../../types/storeTypes';
import {subscriptionTypes} from '../types';

const initialState = {
  subscriptionList: [],
};

const positionsReducer = (state = initialState, action: SubscriptionReducerType) => {
  switch (action.type) {
    case subscriptionTypes.GET_SUBSCRIPTIONS_BY_POSITION_ID:
      return {
        ...state,
        subscriptionList: action.subscriptionList
      };
    default:
      return state;
  }
};

export default positionsReducer;
