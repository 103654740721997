import React, {useEffect, useState} from 'react';
import {Row, Col, Space, Tooltip, Popconfirm} from 'antd';
import {ArrowLeftOutlined, EditOutlined, DeleteOutlined, LaptopOutlined,
  SolutionOutlined, ShareAltOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import {deletePosition, getPositionById, getPositionParams} from '../../../store/redux/actions/positionActions';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Components and Subcomponents
import TitleComponent from '../../subComponents/TitleComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';
import ApplicantList from './ApplicantList';
import SharePositionModal from './SharePositionModal';

// Functions and Constants
import {currencyFormat, getDateDifference, getWindowInformation, isEmpty,
  redirectWindow, userIsAdmin} from '../../../utils/functions';
import {routes} from '../../../utils/routes';
import baseURL from '../../../api/baseURL';


const PositionDetail = () => {
  const dispatch = useAppDispatch();
  const [sharePositionModalVisible, setSharePositionModalVisible] = useState(false);
  const positionDetail = useSelector(positionSelectors.positionDetailSelector);
  const applications = useSelector(positionSelectors.positionApplicationsSelector) ?? [];
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const team = positionParams!.teamOptions.find((param) => param.id === positionDetail!.idTeam)?.value;
  const contract = positionParams!.contractTypes.find((param) => param.id === positionDetail!.idContract)?.value;
  const formation = positionParams!.formationOptions.find((param) => param.id === positionDetail!.idFormations)?.value;
  const workingHours = positionParams!.workingHourOptions.find((param) => param.id === positionDetail!.idWorkingHours)?.value;
  const detailIsEmpty = isEmpty(positionDetail);

  useEffect(() => {
    const { search } = getWindowInformation();
    const urlParams = new URLSearchParams(search);
    const positionId = urlParams.get('idVacante') as string;

    if(isEmpty(positionId)){
      handleReturnPositions();
    } else {
      dispatch(getPositionParams(getPositionById(positionId)))
    }
  }, []);
  
  const handleReturnPositions = () => {
    redirectWindow(routes.positions.view);
  };

  const handleEditPosition = (idPosition: string) => {
    redirectWindow(`${routes.positions.edit}?idVacante=${idPosition}`);
  };

  return (
    <Row>
      {!detailIsEmpty &&
        <>
          <Col md={24} xs={24}>
        <span className={'mb-2 cursor-pointer text-grey-dkt-500 font-medium text-sm hover:bg-[transparent]'}
              onClick={handleReturnPositions}>
          <ArrowLeftOutlined /> Vacantes
        </span>
          </Col>

          <Col md={24} xs={24} className={'mt-5 py-10 px-10 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
            <Col md={24} xs={24} className={'md:flex mb-3'}>
              <Col lg={8} md={14} xs={24} className={'grid'}>
                <Col md={24} xs={24} className={'mb-4'}>
                  <Space size={'middle'}>
                    <Tooltip title={'Compartir'}>
                      <span>
                          <ButtonComponent text={''} icon={<ShareAltOutlined/>}
                                            className={'bg-green-dkt-400 hover:bg-green-dkt-500 focus:outline-none focus:ring-2 ' +
                                              'focus:ring-offset-2 focus:ring-green-dkt-300 after:content-none text-white'}
                                            onClick={() => setSharePositionModalVisible(true)} />

                      </span>
                    </Tooltip>
                    {userIsAdmin() &&
                      <Tooltip title={'Editar'}>
                        <span>
                          <ButtonComponent text={''} icon={<EditOutlined/>}
                                          className={'bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                            'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none text-white '}
                                          onClick={() => handleEditPosition(positionDetail!.idPosition ?? '')}/>
                        </span>
                      </Tooltip>
                    }
                    {userIsAdmin() &&
                      <Tooltip title={'Eliminar'}>
                        <span>
                          <Popconfirm title={'¿Deseas eliminar la vacante?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                      onConfirm={() => dispatch(deletePosition(positionDetail!.idPosition ?? ''))}>
                            <ButtonComponent text={''} icon={<DeleteOutlined/>}
                                            className={'bg-red-dkt-400 hover:bg-red-dkt-500 focus:outline-none focus:ring-2 ' +
                                              'focus:ring-offset-2 focus:ring-red-dkt-300 after:content-none text-white '}/>
                          </Popconfirm>
                        </span>
                      </Tooltip>
                    }
                  </Space>
                </Col>
                
                <Space size={'small'} direction={'vertical'} className={'mb-auto'}>
                  <TitleComponent level={4} text={positionDetail!.title}
                                  className={'leading-6 font-medium xs:font-normal text-blue-dkt-400'} />

                  <Col md={24} xs={24} className={'text-grey-dkt-500'}>
                    <span><LaptopOutlined title={'Cargo'} /> {team}</span>
                    <span className={'hidden md:inline-block'}>&nbsp;&bull;&nbsp;</span>
                    <br className={'md:hidden'} />
                    <span className={'text-grey-dkt-600'}><SolutionOutlined title={'Tipo de contrato'} /> {contract}</span>
                  </Col>

                  <Col md={24} xs={24} className={'mt-2 md:mt-0 text-grey-dkt-500'}>
                    {`Publicado hace ${getDateDifference(positionDetail!.timeStamp)}`}
                    <span className={'hidden md:inline-block'}>&nbsp;&bull;&nbsp;</span>
                    <br className={'md:hidden'} />
                    {(applications.length === 0 || applications.length > 1)
                      ? `${applications?.length} candidatos` : '1 candidato'
                    }
                  </Col>
                </Space>
              </Col>
              <Col lg={16} md={10} xs={0}
                   className={'h-40 bg-center bg-cover bg-no-repeat'}
                   style={{
                     backgroundImage: `url('${baseURL! + positionDetail!.image}')`,
                     clipPath: 'polygon(24% 0, 100% 0, 100% 100%, 0 100%)'
                   }} />
            </Col>

            <Col md={24} xs={24} className={'mt-6 md:flex border-x border-y rounded border-grey-dkt-300'}>
              <Col md={8} xs={24} className={'text-center border-b md:border-r md:border-b-0 border-grey-dkt-300 py-2'}>
                <span className={'text-grey-dkt-400'}>Nivel de estudio</span>
                <br />
                <span>{formation}</span>
              </Col>
              <Col md={8} xs={24} className={'text-center border-b md:border-r md:border-b-0 border-grey-dkt-300 py-2'}>
                <span className={'text-grey-dkt-400'}>Horas semanales</span>
                <br />
                <span>{workingHours}</span>
              </Col>
              <Col md={8} xs={24} className={'text-center py-2'}>
                <span className={'text-grey-dkt-400'}>Salario</span>
                <br />
                <span>$ {currencyFormat(positionDetail!.salary)}</span>
              </Col>
            </Col>

            <Col md={24} xs={24} className={'py-4'}>
              <Col md={24} xs={24}>
                <TitleComponent level={5} text={'Tareas a realizar'} className={'leading-6'} />
              </Col>
              <Col md={24} xs={24} dangerouslySetInnerHTML={{ __html: positionDetail!.description }} />
            </Col>

            <Col md={24} xs={24} className={'py-4'}>
              <Col md={24} xs={24}>
                <TitleComponent level={5} text={'Perfil del aspirante'} className={'leading-6'} />
              </Col>
              <Col md={24} xs={24} dangerouslySetInnerHTML={{ __html: positionDetail!.mandatories }} />
            </Col>

            <Col md={24} xs={24} className={'py-4'}>
              <Col md={24} xs={24}>
                <TitleComponent level={5} text={'Beneficios'} className={'leading-6'} />
              </Col>
              <Col md={24} xs={24} dangerouslySetInnerHTML={{ __html: positionDetail!.experience }} />
            </Col>

            <Col md={24} xs={24} className={'pt-4'}>
              <Col md={24} xs={24}>
                <TitleComponent level={5} text={'Candidatos'} className={'leading-6'} />
              </Col>
              <Col md={24} xs={24}>
                <ApplicantList />
              </Col>
            </Col>
          </Col>

          {sharePositionModalVisible &&
            <SharePositionModal onToggleModal={setSharePositionModalVisible} />
          }
        </>
      }
    </Row>
  );
}

export default PositionDetail;