import React, {useEffect, useState} from 'react';
import { Col, Row, Tabs } from 'antd';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import Categories from './subComponents/Categories';
import Teams from './subComponents/Teams';
import Places from './subComponents/Places';
import WorkingHours from './subComponents/WorkingHours';
import Formations from './subComponents/Formations';
import AddEditSettingModal from './subComponents/AddEditSettingModal';
import Referrals from './subComponents/Referrals';
import Languages from './subComponents/Languages';
import Sports from './subComponents/Sports';
import Events from './subComponents/Events';

// Constants and Functions
import {routes} from '../../utils/routes';
import {getWindowInformation, redirectWindow} from '../../utils/functions';


const Settings = () => {
  const [addEditModalVisible, setAddEditModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [section, setSection] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [currentActiveKey, setActiveKey] = useState(getWindowInformation().pathname);

  useEffect(() => {
    const { pathname } = getWindowInformation();
    setActiveKey(pathname);
  }, []);

  const handleAddEditModalVisible = (visible: boolean, isEditing?: boolean, section?: string, title?: string) =>  {
    setIsEditing(isEditing ?? false);
    setAddEditModalVisible(visible);
    setSection(section ?? '');
    setModalTitle(title ?? '');
  };

  const handleChangeTab = (route: string) => {
    redirectWindow(route);
  };

  return (
    <Row>
      <Col md={24} sm={24} xs={24}>
        <TitleComponent level={3} text={'Configuración'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <Tabs activeKey={currentActiveKey} centered size={'small'} onChange={handleChangeTab}>
          <Tabs.TabPane tab={'Categorías'} key={routes.settings.categories}>
            <Categories onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Equipos'} key={routes.settings.teams}>
            <Teams onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Tiendas'} key={routes.settings.stores}>
            <Places onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Horarios'} key={routes.settings.schedules}>
            <WorkingHours onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Niveles de estudio'} key={routes.settings.studyLevels}>
            <Formations onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Idiomas'} key={routes.settings.languages}>
            <Languages onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Convenio'} key={routes.settings.referrals}>
            <Referrals onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Deportes'} key={routes.settings.sports}>
            <Sports onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Eventos'} key={routes.settings.events}>
            <Events onToggleModal={handleAddEditModalVisible} />
          </Tabs.TabPane>
        </Tabs>
      </Col>

      {addEditModalVisible &&
        <AddEditSettingModal isEditing={isEditing} title={modalTitle} section={section}
                             onToggleModal={handleAddEditModalVisible} />
      }
    </Row>
  )
}

export default Settings;