import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Col, Layout, Menu} from 'antd';
import {HomeOutlined, SettingOutlined, ProfileOutlined, SafetyOutlined, LogoutOutlined, EditOutlined} from '@ant-design/icons';

// Store
import * as generalSelectors from '../../store/redux/selectors/generalSelectors';
import * as authSelectors from '../../store/redux/selectors/authSelectors';
import * as userSelectors from '../../store/redux/selectors/userSelectors';
import {setCurrentRoute} from '../../store/redux/actions/generalActions';
import {useAppDispatch} from '../../store/store';
import {getSessionState} from '../../store/redux/actions/authActions';
import {getUserInfo} from '../../store/redux/actions/userActions';

// Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import {WARNING_MODAL_ON_OK} from '../subComponents/responseModals';

// Constants and Functions
import {routes} from '../../utils/routes';
import {
  clearStorage,
  getLocalStorageItem,
  getWindowInformation,
  reloadWindow,
  renderSectionByRole
} from '../../utils/functions';
import { noteType } from '../../types/userTypes';
import logo from '../../assets/DKT_Lockup_Primary_White_RGB.png';

const {Sider} = Layout;

const Navbar = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRoute = useSelector(generalSelectors.currentRouteSelector) ?? routes.home;
  const userInfo = useSelector(userSelectors.userInfoSelector);
  const signOff = useSelector(authSelectors.signOffSelector);
  const roleName = getLocalStorageItem('roleName');
  const place = getLocalStorageItem('place');
  const notesInLocalStorage = getLocalStorageItem('notes');
  const userName = userInfo?.userName ?? '';
  const setNavItems = () => {
    const navItems = [];
    renderSectionByRole('dashboard') &&
      navItems.push({ key: routes.home, icon: <HomeOutlined />, label: isCollapsed ? '' : 'Dashboard' });
    renderSectionByRole('positions') &&
      navItems.push({ key: routes.positions.view, icon: <ProfileOutlined />, label: isCollapsed ? '' : 'Vacantes' });
    renderSectionByRole('access') &&
      navItems.push({ key: routes.users, icon: <SafetyOutlined  />, label: isCollapsed ? '' : 'Accesos' });
    renderSectionByRole('configuration') &&
      navItems.push({ key: routes.settings.home, icon: <SettingOutlined />, label: isCollapsed ? '' : 'Configuración' });
    renderSectionByRole('contents') &&
      navItems.push({ key: routes.contents.home, icon: <EditOutlined />, label: isCollapsed ? '' : 'Contenidos' });

    return navItems;
  };

  const navItems = setNavItems();

  useEffect(() => {
    const { pathname } = getWindowInformation();
    dispatch(setCurrentRoute(pathname));
    dispatch(getSessionState());

    const interval = setInterval(() => {
      dispatch(getSessionState());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const notes: noteType = {
      notes: notesInLocalStorage ?? ''
    } 

    dispatch(getUserInfo(notes));
  }, []);

  const handleRouteSelector = (route: string) => {
    dispatch(setCurrentRoute(route));
    navigate(route, { replace: false });
  };

  const handleLogout = () => {
    dispatch(setCurrentRoute(routes.home));
    clearStorage();
    reloadWindow();
  };

  useEffect(() => {
    if(signOff){
      WARNING_MODAL_ON_OK('Tu sesión se cerrará porque has sido trasladado de tienda.', handleLogout);
    }
  }, [signOff]);

  return (
    <Sider breakpoint="md"
           className={'h-screen min-h-screen bg-grey-dkt-800 border-r md:flex md:flex-shrink-0 sticky bottom-0 top-0'}
           onBreakpoint={broken => {
             setCollapsed(broken);
           }}
    >
      <Col className={'flex flex-col pt-4 pb-4'}>
        <Col className="logo p-2 min-h-20">
          <img className="w-full mx-auto"
               src={logo} />
        </Col>
        <Menu mode="inline" selectedKeys={[currentRoute]} items={navItems}
              className={'border-0 bg-grey-dkt-800 text-grey-dkt-300 [&>li]:px-4 ' +
                '[&>.ant-menu-item-selected]:bg-grey-dkt-800 [&>.ant-menu-item-selected]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):hover]:bg-grey-dkt-700 [&>li:hover]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):active]:bg-grey-dkt-800 [&>li:after]:border-r-0 ' +
                '[&>.ant-menu-item-selected:after]:border-0 ' +
                `${isCollapsed ? '[&>li>.anticon]:ml-4 ' : '[&>li>.anticon]:ml-0 '}`
              }
              onClick={(item) => handleRouteSelector(item.key)}
        />
      </Col>
      <Col className={'flex border-t border-grey-dkt-600 p-4 bg-red bottom-0 absolute w-full'}>
        <Col className={'flex-shrink-0 w-full'}>
          {!isCollapsed &&
            <Col className={'group'}>
              <p className={'text-sm font-medium text-grey-dkt-300 group-hover:text-grey-dkt-200'}>
                {userName}
              </p>
              <p className={'text-sm text-grey-dkt-400 group-hover:text-grey-dkt-200'}>
                {roleName}
              </p>
              <p className={'text-sm text-grey-dkt-400 group-hover:text-grey-dkt-200'}>
                {place}
              </p>
            </Col>
          }
          <ButtonComponent text={isCollapsed ? '' : 'Cerrar sesión'}
                           icon={<LogoutOutlined />}
                           className={'text-white w-full h-10 bg-orange-dkt-400 hover:bg-grey-dkt-700 ' +
                             `${isCollapsed ? 'mt-0 ' : 'mt-4 '}`
                           }
                           onClick={handleLogout} />
        </Col>
      </Col>
    </Sider>
  );
}

export default Navbar;