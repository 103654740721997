// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import {Col, Descriptions} from 'antd';

// Store
import * as applicationsSelector from '../../../../store/redux/selectors/applicationSelectors';
import * as positionSelectors from '../../../../store/redux/selectors/positionSelectors';

// Functions and Constants
import {getDateDifference} from '../../../../utils/functions';


const GeneralInfo = () => {
  const applicationDetail = useSelector(applicationsSelector.applicationsDetailSelector);
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const applicant = applicationDetail.application.applicant!;
  const referredChannelOptions = positionParams!.referredChannelOptions;
  const referredChannel = referredChannelOptions.find((param) => param.id === applicant.idReferred);
  
  return (
    <Col md={24} xs={24} className={'mb-2 bg-white p-4 rounded flex md:block'}>
      <Descriptions column={1} size={'small'}
                    labelStyle={{ padding: '5px 5px', fontWeight: '600', width: '40%', backgroundColor: 'transparent' }}
                    contentStyle={{ padding: '5px 0' }}
                    className={'[&>div>table>tbody>tr>td>div>span]:after:content-none'}>
        <Descriptions.Item label="Nombres">{applicant!.firstName}</Descriptions.Item>
        <Descriptions.Item label="Apellidos">{applicant!.lastName}</Descriptions.Item>
        <Descriptions.Item label="Edad">{getDateDifference(applicant!.birthDate)}</Descriptions.Item>
        <Descriptions.Item label="Documento">{applicant!.typeDocument} {applicant!.document}</Descriptions.Item>
        <Descriptions.Item label="Dirección">{applicant!.address}</Descriptions.Item>
        <Descriptions.Item label="Ciudad">{applicant!.city}</Descriptions.Item>
        <Descriptions.Item label="Teléfono">{applicant!.phone}</Descriptions.Item>
        <Descriptions.Item label="Correo electrónico">{applicant!.email}</Descriptions.Item>
        <Descriptions.Item label="Canal">{referredChannel?.value}</Descriptions.Item>
        {referredChannel?.isPrincipal && 
          <Descriptions.Item label="Correo del referido">{applicant?.referredEmail}</Descriptions.Item>
        }
      </Descriptions>
    </Col>
  );
}

export default GeneralInfo;