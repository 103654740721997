// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import userServices from '../../../api/userServices';

// Types
import {userTypes} from '../types';
import {UserType, noteType} from '../../../types/userTypes';
import {PaginationType} from '../../../types/sharedTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';



// #region GET

export const getUserParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getParams()
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_PARAMS,
        userParams: response.data
      });
      dispatch(getAllUsers(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const getAllUsers = (data?: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getAll(data)
    .then((response) => {
      dispatch({
        type: userTypes.GET_ALL_USERS,
        userList: response.data.rows
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const getUserInfo = (data: noteType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getUserInfo(data)
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_INFO,
        userInfo: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
    })
}

export const createUser = (data: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.add(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region PUT

export const updateUser = (data: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.update(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region DELETE

export const deleteUser = (data: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.deleteUser(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion