// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';

// Services
import applicantServices from '../../../api/applicantServices';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';

// Functions
import {base64ToFile} from '../../../utils/functions';


// #region GET

export const downloadApplicantFile = (filename: string) => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  applicantServices.downloadApplicantFile(filename)
    .then((response) => {
      base64ToFile(response.data, filename)
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion