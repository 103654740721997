// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../../store/store';
import {useSelector} from 'react-redux';

// Store
import * as userSelector from '../../../store/redux/selectors/userSelectors';

// Types
import {UserType} from '../../../types/userTypes';

// Components
import SelectComponent from '../../subComponents/SelectComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import { isEmpty } from '../../../utils/functions';

// Actions
import { createUser, updateUser } from '../../../store/redux/actions/userActions';


type AccessControlModalType = {
  userToEdit?:UserType;
  onToggleModal: (visible: boolean) => void;
};
const AccessControlModal: FunctionComponent<AccessControlModalType> = (
  {
    userToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const userParams = useSelector(userSelector.userParamsSelector);

  const handleSubmit = (values: UserType) => {
    if(userToEdit){
      values.idUser = userToEdit.idUser;

      dispatch(updateUser(values));
    }else{
      dispatch(createUser(values));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={'Control de accesos'} visible={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
            <ButtonComponent key={'cancel'} text={'Cerrar'}
                            className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                              'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                            onClick={() => onToggleModal(false)} />,
            <ButtonComponent key={'submit'} text={'Dar acceso'}
                            className={'text-white w-32 h-8 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} 
                            onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={userToEdit ?? {}}
            onFinish = {handleSubmit}>
        <Row gutter={10}>
          <Col md={24} xs={24}>
            <FormItemComponent name={'email'} label={'Email del colaborador'} required={true}
                              child={<InputComponent disabled={!isEmpty(userToEdit)} placeHolder={'Ej: correo@decathlon.com'} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'role'} label={'Rol'} required={true}
                              child={<SelectComponent list={userParams.roleTypes} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'idPlace'} label={'Lugar'} required={true}
                              child={<SelectComponent list={userParams.placeOptions} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'idTeam'} label={'Equipo'} required={true}
                              child={<SelectComponent list={userParams.teamOptions} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AccessControlModal.propTypes = {
  userToEdit: PropTypes.any,
  onToggleModal: PropTypes.func.isRequired,
};
AccessControlModal.defaultProps = {
  userToEdit: undefined,
  onToggleModal: () => {
    return null;
  }
};

export default AccessControlModal;