import {StoreType} from '../../../types/storeTypes';

export const positionParamsSelector = (state: StoreType) => {
  return state.positionsReducer.positionParams;
}

export const allPositionsSelector = (state: StoreType) => {
  return state.positionsReducer.positionList;
}

export const positionDetailSelector = (state: StoreType) => {
  return state.positionsReducer.positionDetail;
}

export const positionApplicationsSelector = (state: StoreType) => {
  return state.positionsReducer.positionApplications;
}
