/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Auth ---------------*/
export const authTypes = {
  SIGN_OFF: 'SIGN_OFF',
};

/* ---------------- Dashboard -----------------*/
export const dashboardTypes = {
  GET_DASHBOARD: 'GET_DASHBOARD',
};

/* --------------- Session ---------------*/
export const sessionTypes = {
  LOGGED_IN: 'LOGGED_IN',
};

/* --------------- Positions ---------------*/
export const positionTypes = {
  GET_POSITION_PARAMS: 'GET_POSITION_PARAMS',
  GET_ALL_POSITIONS: 'GET_ALL_POSITIONS',
  GET_POSITION_DETAIL: 'GET_POSITION_DETAIL',
};

/* --------------- Positions ---------------*/
export const subscriptionTypes = {
  GET_SUBSCRIPTIONS_BY_POSITION_ID: 'GET_SUBSCRIPTIONS_BY_POSITION_ID',
};

/* ---------------- Users -----------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_USER_INFO: 'GET_USER_INFO',
};

/* ---------------- Application -----------------*/
export const applicationTypes = {
  GET_ALL_APPLICATIONS: 'GET_ALL_APPLICATIONS',
  GET_APPLICATIONS_PARAMS: 'GET_APPLICATIONS_PARAMS',
  GET_APPLICATION: 'GET_APPLICATION',
  RESET_APPLICATION_VALUES: 'RESET_APPLICATION_VALUES'
};

/* --------------- Settings ---------------*/
export const settingTypes = {
  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
  GET_ALL_PLACES: 'GET_ALL_PLACES',
  GET_TEAM_PARAMS: 'GET_TEAM_PARAMS',
  GET_ALL_TEAMS: 'GET_ALL_TEAMS',
  GET_ALL_WORKING_HOURS: 'GET_ALL_WORKING_HOURS',
  GET_ALL_FORMATIONS: 'GET_ALL_FORMATIONS',
  GET_ALL_REFERRALS: 'GET_ALL_REFERRALS',
  GET_ALL_LANGUAGES: 'GET_ALL_LANGUAGES',
  GET_SETTING_DETAIL: 'GET_SETTING_DETAIL',
  GET_ALL_SPORTS: 'GET_ALL_SPORTS',
  GET_EVENT_PARAMS: 'GET_EVENT_PARAMS',
  GET_ALL_EVENTS: 'GET_ALL_EVENTS',
};

/* --------------- Contents ---------------*/
export const contentTypes = {
  GET_ABOUT_US_CONTENT: 'GET_ABOUT_US_CONTENT',
  GET_OUR_TEAM_CONTENT: 'GET_OUR_TEAM_CONTENT',
  GET_POSITION_CONTENT: 'GET_POSITION_CONTENT',
};