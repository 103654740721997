// Dependencies
import { combineReducers } from 'redux';

// Reducers
import generalReducer from './generalReducer';
import authReducer from './authReducer';
import sessionReducer from './sessionReducer';
import positionsReducer from './positionsReducer';
import subscriptionsReducer from './subscriptionReducer';
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';
import dashboardReducer from './dashboardReducer';
import applicationsReducer from './applicationsReducer';
import contentsReducer from './contentsReducer';

// Reducers
export default combineReducers({
  generalReducer: generalReducer,
  authReducer: authReducer,
  sessionReducer: sessionReducer,
  positionsReducer: positionsReducer,
  subscriptionsReducer: subscriptionsReducer,
  userReducer: userReducer,
  applicationsReducer: applicationsReducer,
  settingsReducer: settingsReducer,
  dashboardReducer: dashboardReducer,
  contentsReducer: contentsReducer
});