// Constants
import {PositionReducerType} from '../../../types/storeTypes';
import {positionTypes} from '../types';

const initialState = {
  positionList: [],
  positionParams: {
    contractTypes: [],
    experienceTypes: [],
    formationOptions: [],
    placeOptions: [],
    referredChannelTypes: [],
    sportOptions: [],
    teamOptions: [],
    workingHourOptions: [],
  },
  positionDetail: {},
  positionApplications: [],
};

const positionsReducer = (state = initialState, action: PositionReducerType) => {
  switch (action.type) {
    case positionTypes.GET_POSITION_PARAMS:
      return {
        ...state,
        positionParams: action.positionParams
      };
    case positionTypes.GET_ALL_POSITIONS:
      return {
        ...state,
        positionList: action.positionList
      };
    case positionTypes.GET_POSITION_DETAIL:
      return {
        ...state,
        positionDetail: action.positionDetail,
        positionApplications: action.positionApplications,
      };
    default:
      return state;
  }
};

export default positionsReducer;
