import request from './requestWrapper';

// Types
import {UserType, noteType} from '../types/userTypes';
import {PaginationType} from '../types/sharedTypes';


// #region GET

function getParams() {
  return request({
    url: 'users/getParams',
    method: 'GET',
  });
}

function getAll(data?: PaginationType) {
  return request({
    url: 'users/getAll',
    method: 'GET',
    params: data
  });
}

// #region POST

function getUserInfo(data: noteType) {
  return request({
    url: 'users/getUserNotes',
    method: 'POST',
    data: data
  });
}

function add(data: UserType) {
  return request({
    url: 'users/create',
    method: 'POST',
    data: data
  });
}

// #endregion

// #region PUT

function update(data: UserType) {
  return request({
    url: 'users/update',
    method: 'PUT',
    data: data
  });
}

// #endregion

// #region DELETE

function deleteUser(idUser: string) {
  return request({
    url: `users/delete/${idUser}`,
    method: 'DELETE',
  });
}

// #endregion


export default {
  getParams, getAll, getUserInfo, add, update, deleteUser
}