import { GetApplicationsType } from '../types/applicationsTypes';
import request from './requestWrapper';

// #region GET

function getAllApplicationInformationById(idApplication: string) {
  return request({
    url: `applications/getAllApplicationInformationById/${idApplication}`,
    method: 'GET',
  });
}

function getParams() {
  return request({
    url: 'applications/getParams',
    method: 'GET'
  });
}

// #endregion

// #region POST

function getAll(data: GetApplicationsType) {
  return request({
    url: 'applications/getAll',
    method: 'POST',
    data: data
  });
}

// #endregion

export default {
  getAllApplicationInformationById, getParams, getAll
}