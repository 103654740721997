// Functions and Constants
import {SessionReducerType} from '../../../types/storeTypes';
import {isSignedIn} from '../../../utils/sessionUtils';

const initialState = {
  loggedIn: isSignedIn(),
};

const sessionReducer = (state = initialState, action: SessionReducerType) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default sessionReducer;
