// Types
import {ContentsReducerType} from '../../../types/storeTypes';
import {contentTypes} from '../types';

const initialState = {
  aboutUsContent: {},
  ourTeamContent: {},
  positionContent: {}
}

const contentsReducer = (state = initialState, action: ContentsReducerType) => {
  switch (action.type) {  
    case contentTypes.GET_ABOUT_US_CONTENT:
      return {
        ...state,
        aboutUsContent: action.aboutUsContent
      };
    case contentTypes.GET_OUR_TEAM_CONTENT:
      return {
        ...state,
        ourTeamContent: action.ourTeamContent
      };
    case contentTypes.GET_POSITION_CONTENT:
      return {
        ...state,
        positionContent: action.positionContent
      };
    default:
      return state;
  }
};

export default contentsReducer;
