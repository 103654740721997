// Libraries
import React from 'react';
import {useSelector} from 'react-redux';
import {Card, Col, Empty, Row} from 'antd';
import {EnvironmentOutlined, LaptopOutlined} from '@ant-design/icons';

// Store
import * as applicationsSelector from '../../../../store/redux/selectors/applicationSelectors';
import * as positionSelectors from '../../../../store/redux/selectors/positionSelectors';

// Functions and constants
import {redirectWindow} from '../../../../utils/functions';
import {routes} from '../../../../utils/routes';
import TitleComponent from '../../../subComponents/TitleComponent';


const OtherApplications = () => {
  const applicationDetail = useSelector(applicationsSelector.applicationsDetailSelector);
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const othersApplication = applicationDetail.othersApplication;
  const placeOptions = positionParams!.placeOptions;
  const teamOptions = positionParams!.teamOptions;

  const handleRedirectDetail = (positionId: string) => {
    redirectWindow(`${routes.positions.detail}?idVacante=${positionId}&subscription=${true}`);
  };

  return (
    <Col className={'mb-2 bg-white p-4 rounded'}>
      <Row>
        <Col md={24} xs={24}>
          <TitleComponent level={5} text={'Otras aplicaciones:'} className={'text-sm font-medium text-grey-dkt-400'} />
        </Col>
        {othersApplication.length > 0 ?
          othersApplication.map(application =>
            <Col key={application.idApplications} sm={12} xs={24}>
              <Card key={application.idApplications} bordered={false} className={'[&>div]:p-0'}>
                <span onClick={() => handleRedirectDetail(application.placePosition!.position.idPosition  ?? '')}
                      className={'cursor-pointer'}>
                  {application.placePosition!.position.title}
                </span>
                <br/>
                <span>
                  <LaptopOutlined title={'Equipo'} className={'mr-2'} /> 
                  {teamOptions.find((param) => param.id === application.placePosition!.position.idTeam)?.value}
                </span>
                <br/>
                <span>
                  <EnvironmentOutlined title={'Tienda'} className={'mr-2'} />
                  {placeOptions.find((place) => place.id === application.placePosition!.idPlace)?.value}
                </span>
              </Card>
            </Col>
          )
        :
          <Empty description={'No ha aplicado a otras vacantes'}/>
        }
      </Row>
    </Col>
  );
}

export default OtherApplications;