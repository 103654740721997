import request from './requestWrapper';

// #region GET

function downloadApplicantFile(filename: string) {
  return request({
    url: `applicants/downloadApplicantFile/${filename}`,
    method: 'GET',
  });
}

// #endregion

export default {
  downloadApplicantFile
}