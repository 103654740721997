import {clearStorage, getLocalStorageItem, isEmpty} from './functions';

export const isSignedIn = () => {
  const accessToken = getLocalStorageItem('accessToken');
  const role = getLocalStorageItem('role');
  const roleName = getLocalStorageItem('roleName');
  const notes = getLocalStorageItem('notes');
  const expiredIn = getLocalStorageItem('expiredIn');
  const incorrectInfo = isEmpty(accessToken) || isEmpty(role) || isEmpty(roleName) || isEmpty(notes) || isEmpty(expiredIn)
    || roleName === 'Desconocido';
  const tokenExpired = new Date() > new Date(expiredIn!);

  if(incorrectInfo || tokenExpired) clearStorage();

  return !incorrectInfo && !tokenExpired;
};