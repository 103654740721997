// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';

// Services
import applicationServices from '../../../api/applicationServices';

// Types
import {applicationTypes} from '../types';
import {ApplicationType} from '../../../types/applicationsTypes';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';

// Functions
export const resetGetByApplicantIdAndPlacePositionId = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: applicationTypes.RESET_APPLICATION_VALUES,
  });
}

// #region GET

export const getAllApplicationInformationById = (idApplication: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  applicationServices.getAllApplicationInformationById(idApplication)
    .then((response) => {
      dispatch({
        type: applicationTypes.GET_APPLICATION,
        applicationDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getApplicationParams = (idApplication: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch(resetGetByApplicantIdAndPlacePositionId());
  applicationServices.getParams()
    .then((response) => {
      dispatch({
        type: applicationTypes.GET_APPLICATIONS_PARAMS,
        applicationParams: response.data
      });
      dispatch(getAllApplicationInformationById(idApplication));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion