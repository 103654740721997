// Store
import {setLoading} from './generalActions';

// Store
import contentServices from '../../../api/contentServices';
import {AppDispatch} from '../../store';

// Types
import {contentTypes} from '../types';
import {AboutUsContentType, OurTeamContentType, PositionContentType} from '../../../types/contentTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


// #region GET

export const getAboutUsContent = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.getAboutUsContent()
    .then((response) => {
      dispatch({
        type: contentTypes.GET_ABOUT_US_CONTENT,
        aboutUsContent: response.data        
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getOurTeamContent = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.getOurTeamContent()
    .then((response) => {
      dispatch({
        type: contentTypes.GET_OUR_TEAM_CONTENT,
        ourTeamContent: response.data        
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getPositionContent = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.getPositionContent()
    .then((response) => {
      dispatch({
        type: contentTypes.GET_POSITION_CONTENT,
        positionContent: response.data        
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const setAboutUsContent = (data: AboutUsContentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.setAboutUsContent(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAboutUsContent());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const setOurTeamContent = (data: OurTeamContentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.setOurTeamContent(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getOurTeamContent());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const setPositionContent = (data: PositionContentType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  contentServices.setPositionContent(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getPositionContent());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion