import request from './requestWrapper';

// #region GET

function getSessionState() {
  return request({
    url: 'auth/getSessionState',
    method: 'GET',
  });
}

// #endregion

export default {
  getSessionState
}