// Store
import {setLoading} from './generalActions';

// Services
import positionServices from '../../../api/positionServices';

// Types
import {GetPositionsType, PositionType, SharePositionsType} from '../../../types/positionTypes';
import {AppDispatch} from '../../store';
import {positionTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL, SUCCESS_MODAL_ON_OK} from '../../../components/subComponents/responseModals';

// Constants and Functions
import {redirectWindow} from '../../../utils/functions';
import {routes} from '../../../utils/routes';


// #region GET

export const getPositionParams = (callback?: (data?: any) => Promise<void>) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.getParams()
    .then((response) => {
      dispatch({
        type: positionTypes.GET_POSITION_PARAMS,
        positionParams: response.data
      });
      callback && dispatch(callback);
      !callback && dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllPositions = (data: GetPositionsType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.getAll(data)
    .then((response) => {
      dispatch({
        type: positionTypes.GET_ALL_POSITIONS,
        positionList: response.data.rows
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getPositionById = (positionId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.getPositionById(positionId)
    .then((response) => {
      dispatch({
        type: positionTypes.GET_POSITION_DETAIL,
        positionDetail: response.data.description,
        positionApplications: response.data.applications,
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const addPositionImage = (positionImage: FormData, data: PositionType, isEditing: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.addPositionImage(positionImage)
    .then(response => {
      data.image = response.data.imageUrl;
      if(isEditing)
        dispatch(updatePosition(data, () => redirectWindow(routes.positions.view)));
      else
        dispatch(addPosition(data, () => redirectWindow(routes.positions.view)));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const addPosition = (data: PositionType, callback: () => void) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.add(data)
    .then(response => {
      SUCCESS_MODAL_ON_OK(response.data.status, callback);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createMassiveExEmployees = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.createMassiveExEmployees(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const sharePosition = (data: SharePositionsType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.sharePosition(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region PUT

export const updatePosition = (data: PositionType, callback?: () => void) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.update(data)
    .then(response => {
      if(callback){
        SUCCESS_MODAL_ON_OK(response.data.status, callback);
      } else {
        SUCCESS_MODAL(response.data.status);
        dispatch(getAllPositions({paginationVm:{page: 1, size: 5}, filterVm:{}}));
      }
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region DELETE

export const deletePosition = (positionId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  positionServices.deletePosition(positionId)
    .then(response => {
      SUCCESS_MODAL_ON_OK(response.data.status, () => redirectWindow(routes.positions.view));
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion