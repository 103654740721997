import React, {useEffect} from 'react';
import {Row, Col} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store/store';

// Store
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';
import {getPositionById} from '../../../store/redux/actions/positionActions';

// Components and Subcomponents
import TitleComponent from '../../subComponents/TitleComponent';
import PositionForm from './PositionForm';

// Functions and Constants
import {getWindowInformation, isEmpty, redirectWindow} from '../../../utils/functions';
import {routes} from '../../../utils/routes';

const EditPosition = () => {
  const dispatch = useAppDispatch();
  const positionDetail = useSelector(positionSelectors.positionDetailSelector);
  
  useEffect(() => {
    const { search } = getWindowInformation();
    const urlParams = new URLSearchParams(search);
    const positionId = urlParams.get('idVacante') as string;

    if(isEmpty(positionId)){
      handleReturnPositions();
    } else {
      dispatch(getPositionById(positionId))
    }
  }, []);
  
  const handleReturnPositions = () => {
    redirectWindow(routes.positions.view);
  };

  return (
    <Row>
      <Col md={24} xs={24}>
        <span className={'mb-2 p-0 cursor-pointer text-grey-dkt-500 font-medium text-sm hover:bg-[transparent]'}
              onClick={handleReturnPositions}>
          <ArrowLeftOutlined /> Vacantes
        </span>
      </Col>

      <Col md={24} xs={24}>
        <TitleComponent level={3} text={'Editar vacante'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <PositionForm isEditing={true} positionDetail={positionDetail} />
      </Col>
    </Row>
  );
}

export default EditPosition;