import {useMediaQuery} from 'react-responsive';


export const isEmpty = (element: string | object | null | undefined) => {
  return element === undefined || element === 'undefined'
    || element === null || element === 'null' || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0);
};

/* --------------------- Storage ----------------------- */
export const setSessionStorageItem = (fieldName: string, value: string) => {
  sessionStorage.setItem(fieldName, value);
};
export const setLocalStorageItem = (fieldName: string, value: string) => {
  localStorage.setItem(fieldName, value);
};
export const getSessionStorageItem = (fieldName: string) => {
  return sessionStorage.getItem(fieldName);
};
export const getLocalStorageItem = (fieldName: string) => {
  return localStorage.getItem(fieldName);
};
export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return { pathname, search };
};
export const reloadWindow = () => {
  window.location.reload();
};
export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};
export const screenSizeValidator = (sizeValidator: string) => {
  return useMediaQuery({ query: sizeValidator });
};

/* ----------------------- Currency ------------------------ */
export function currencyFormat(value?: string){
  const text = `${value}`
    .replace('.', ',')
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');

  return `${text}`;
}
export function currencyToNumber(value: string){
  const splitValue = value.split(',');
  let newValue;
  if(splitValue.length === 1){
    newValue = parseFloat(value);
  } else {
    newValue = parseFloat(splitValue[0].replaceAll('.', '') + '.' + splitValue[1]);
  }

  return newValue;
}

/* ----------------------- Dates ------------------------ */
export function getDateDifference(date: string | undefined){
  if(date){
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0);

    const diffTime = Math.abs(currentDate.valueOf() - newDate.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
    let diffMonth = currentDate.getMonth() - (newDate.getMonth() + 1)  + (12 * (currentDate.getFullYear() - newDate.getFullYear()));
    if (currentDate.getDate() >= newDate.getDate()) {
      diffMonth++;
    }
    const diffYear = Math.trunc(diffMonth / 12);

    if(diffDays < 31){
      return diffDays === 1 ? '1 día' : `${diffDays} días`;
    }else if(diffMonth < 12){
      return diffMonth === 1 ? '1 mes' : `${diffMonth} meses`;
    }else{
      return diffYear === 1 ? '1 año' : `${diffYear} años`;
    }
  } else {
    return '-';
  }
}

/* ----------------------- Render by Roles ------------------------ */
export function renderSectionByRole(section: string){
  const currentRole = getLocalStorageItem('role');
  const sectionsByRole = [
    { role: 'admin', sections: ['dashboard', 'positions', 'access', 'configuration', 'contents']},
    { role: 'recruiter', sections: ['dashboard', 'positions']},
  ];
  const sections = sectionsByRole.find(section => section.role === currentRole)?.sections ?? [];

  return sections.includes(section);
}
export function userIsAdmin(){
  const currentRole = getLocalStorageItem('role');

  return currentRole === 'admin';
}

/* ----------------------- Base 64 to File ------------------------ */
export function base64ToFile(data: string, name: string){
    const downloadLink = document.createElement('a'); 
    downloadLink.href = 'data:application/octet-stream;base64,' + data;
    downloadLink.download = name;
    downloadLink.click();
    downloadLink.remove();
}