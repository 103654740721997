// Libraries
import React, {FunctionComponent, useEffect} from 'react';
import {Col, Modal, Row} from 'antd';
import PropTypes from 'prop-types';

// Store
import { useAppDispatch } from '../../../store/store';
import { getApplicationParams } from '../../../store/redux/actions/applicationActions';

// Components and Subcomponents
import ButtonComponent from '../../subComponents/ButtonComponent';
import GeneralInfo from './subComponents/GeneralInfo';
import ApplicationInfo from './subComponents/ApplicationInfo';
import StudiesSkillsInfo from './subComponents/StudiesSkillsInfo';
import Documents from './subComponents/Documents';
import OtherApplications from './subComponents/OtherApplications';


type ApplicantDetailModalType = {
  idApplications: string;
  onToggleModal: (visible: boolean) => void;
};
const ApplicantDetailModal: FunctionComponent<ApplicantDetailModalType> = (
  {
    idApplications, onToggleModal
  }) => {
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getApplicationParams(idApplications));
  }, []);

  return (
    <Modal width={900}
           visible={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           onCancel={() => onToggleModal(false)}
           className={'[&>div]:bg-grey-dkt-100'}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />
           ]}>
        <Row>
          <Col md={9} xs={24} className={'md:pr-1'}>
            <GeneralInfo />
            <StudiesSkillsInfo />
          </Col>
          <Col md={15} xs={24} className={'md:pl-1'}>   
            <ApplicationInfo />
            <Documents />
            <OtherApplications />
          </Col>
        </Row>
      
      
    </Modal>
  );
}

ApplicantDetailModal.propTypes = {
  idApplications: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
ApplicantDetailModal.defaultProps = {
  idApplications: '',
  onToggleModal: () => {
    return null;
  }
};

export default ApplicantDetailModal;