// Store
import {setLoading} from './generalActions';

// Services
import settingServices from '../../../api/settingServices';

// Types
import {AppDispatch} from '../../store';
import {settingTypes} from '../types';
import {CategoryType, SettingType} from '../../../types/settingsTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


// #region GET

export const getAllCategories = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllCategories()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_CATEGORIES,
        categories: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllPlaces = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllPlaces()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_PLACES,
        places: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getTeamParams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getTeamParams()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_TEAM_PARAMS,
        teamParams: response.data
      });
      dispatch(getAllTeams());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllTeams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllTeams()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_TEAMS,
        teams: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllWorkingHours = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllWorkingHours()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_WORKING_HOURS,
        workingHours: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllFormations = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllFormations()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_FORMATIONS,
        formations: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllLanguages = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllLanguages()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_LANGUAGES,
        languages: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllReferrals = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllReferrals()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_REFERRALS,
        referrals: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllSports = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllSports()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_SPORTS,
        sports: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getEventParams = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getEventParams()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_EVENT_PARAMS,
        eventParams: response.data
      });
      dispatch(getAllEvents());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllEvents = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getAllEvents()
    .then((response) => {
      dispatch({
        type: settingTypes.GET_ALL_EVENTS,
        events: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

export const getCategoryById = (categoryId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getCategoryById(categoryId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getPlaceById = (placeId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getPlaceById(placeId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getTeamById = (teamId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getTeamById(teamId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getWorkingHourById = (workingHourId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getWorkingHourById(workingHourId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getFormationById = (formationId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getFormationById(formationId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getLanguageById = (languageId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getLanguageById(languageId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getReferredById = (referredId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getReferredById(referredId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getSportById = (sportId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getSportById(sportId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getEventById = (eventId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.getEventById(eventId)
    .then((response) => {
      dispatch({
        type: settingTypes.GET_SETTING_DETAIL,
        settingDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const addCategoryImage = (categoryImage: FormData, data: SettingType, isEditing: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.addCategoryImage(categoryImage)
    .then(response => {
      data = data as CategoryType;
      data.imageUrl = response.data.imageUrl;
      if(isEditing)
        dispatch(updateCategory(data));
      else
        dispatch(createCategory(data));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createCategory = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createCategory(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCategories());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createPlace = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createPlace(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllPlaces());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createTeam = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createTeam(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllTeams());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createWorkingHour = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createWorkingHour(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllWorkingHours());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createFormation = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createFormation(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllFormations());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createLanguage = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createLanguage(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllLanguages());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createReferred = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createReferred(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllReferrals());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    });
}
export const createSport = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createSport(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllSports());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createEvent = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.createEvent(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllEvents());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region PUT

export const updateCategory = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateCategory(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCategories());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updatePlace = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updatePlace(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllPlaces());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateTeam = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateTeam(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllTeams());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateWorkingHour = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateWorkingHour(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllWorkingHours());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateFormation = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateFormation(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllFormations());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateLanguage = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateLanguage(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllLanguages());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateReferred = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateReferred(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllReferrals());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    });
}
export const updateSport = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateSport(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllSports());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateEvent = (data: SettingType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  settingServices.updateEvent(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllEvents());
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion