// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import { getAllApplicationInformationById } from './applicationActions';

// Services
import eventApplicationServices from '../../../api/eventApplicationServices';

// Types
import {ApplicationEventsType} from '../../../types/applicationsTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


// #region POST

export const createApplicationEvent = (event: ApplicationEventsType, idApplication: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  eventApplicationServices.create(event)
    .then((response) => {
      dispatch(getAllApplicationInformationById(idApplication));
      SUCCESS_MODAL(response.data.status);
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion