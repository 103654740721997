// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/sessionSelectors';

// Components
import Login from '../session/Login';
import Dashboard from '../dashboard/Dashboard';
import Positions from '../positions/Positions';
import NewPosition from '../positions/form/NewPosition';
import EditPosition from '../positions/form/EditPosition';
import PositionDetail from '../positions/detail/PositionDetail';
import Access from '../access/Access';
import Settings from '../settings/Settings';
import Contents from '../contents/Contents';

// Constants and Functions
import {routes} from '../../utils/routes';
import {renderSectionByRole} from '../../utils/functions';

const {Content} = Layout;

function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);

  return (
    <Content className={`flex flex-col w-0 flex-1 overflow-hidden min-h-screen ${isLoggedIn ? 'py-6 px-8' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            {renderSectionByRole('dashboard') && <Route path={routes.home} element={<Dashboard />} />}

            {renderSectionByRole('positions') && <Route path={routes.positions.view} element={<Positions />} />}
            {renderSectionByRole('positions') && <Route path={routes.positions.new} element={<NewPosition />} />}
            {renderSectionByRole('positions') && <Route path={routes.positions.edit} element={<EditPosition />} />}
            {renderSectionByRole('positions') && <Route path={routes.positions.detail} element={<PositionDetail />} />}

            {renderSectionByRole('access') && <Route path={routes.users} element={<Access />} />}

            {renderSectionByRole('configuration') &&
              <Route path={routes.settings.home} element={<Navigate to={routes.settings.categories} />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.categories} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.stores} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.teams} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.schedules} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.studyLevels} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.languages} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.referrals} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.sports} element={<Settings />}/>}
            {renderSectionByRole('configuration') && <Route path={routes.settings.events} element={<Settings />}/>}

            {renderSectionByRole('contents') &&
              <Route path={routes.contents.home} element={<Navigate to={routes.contents.aboutUs} />}/>}
            {renderSectionByRole('contents') && <Route path={routes.contents.aboutUs} element={<Contents />}/>}
            {renderSectionByRole('contents') && <Route path={routes.contents.ourTeam} element={<Contents />}/>}
            {renderSectionByRole('contents') && <Route path={routes.contents.positions} element={<Contents />}/>}

            {renderSectionByRole('access') && <Route path={routes.users} element={<Access />} />}

            <Route path={'*'} element={<Navigate to={routes.home} />} />
          </>
          :
          <>
            <Route path={routes.authentication} element={<Login />} />
            <Route path={routes.login} element={<Login />} />

            <Route path={'*'} element={<Navigate to={routes.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;