import {StoreType} from '../../../types/storeTypes';

export const allCategoriesSelector = (state: StoreType) => {
  return state.settingsReducer.categories;
}

export const allPlacesSelector = (state: StoreType) => {
  return state.settingsReducer.places;
}

export const teamParamsSelector = (state: StoreType) => {
  return state.settingsReducer.teamParams;
}

export const allTeamsSelector = (state: StoreType) => {
  return state.settingsReducer.teams;
}

export const allWorkingHoursSelector = (state: StoreType) => {
  return state.settingsReducer.workingHours;
}

export const allFormationsSelector = (state: StoreType) => {
  return state.settingsReducer.formations;
}

export const allLanguagesSelector = (state: StoreType) => {
  return state.settingsReducer.languages;
}

export const allReferralsSelector = (state: StoreType) => {
  return state.settingsReducer.referrals;
}

export const settingDetailSelector = (state: StoreType) => {
  return state.settingsReducer.settingDetail;
}

export const allSportsSelector = (state: StoreType) => {
  return state.settingsReducer.sports;
}

export const eventParamsSelector = (state: StoreType) => {
  return state.settingsReducer.eventParams;
}

export const allEventsSelector = (state: StoreType) => {
  return state.settingsReducer.events;
}