// Constants
import {DashboardReducerType} from '../../../types/storeTypes';
import {dashboardTypes} from '../types';

const initialState = {
  dashboardInfo: {
    applicantsCount: 0,
    positionsCount: 0,
    activePositionsCount: 0,
    applicationsCount: 0,
    site: '-',
    position: '-'
  }
}

const dashboardReducer = (state = initialState, action: DashboardReducerType) => {
  switch (action.type) {
    case dashboardTypes.GET_DASHBOARD:
      return {
        ...state,
        dashboardInfo: action.dashboardInfo
      };
    default:
      return state;
  }
};

export default dashboardReducer;
