import React from 'react';
import { Col, Row, Tabs } from 'antd';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import AboutUsContents from './subComponents/AboutUsContent';
import OurTeamContent from './subComponents/OurTeamContent';
import PositionContent from './subComponents/PositionContent';

// Constants and Functions
import {routes} from '../../utils/routes';
import {getWindowInformation, redirectWindow} from '../../utils/functions';


const Contents = () => {
  const currentActiveKey = getWindowInformation().pathname;

  const handleChangeTab = (route: string) => {
    redirectWindow(route);
  };

  return (
    <Row>
      <Col md={24} sm={24} xs={24}>
        <TitleComponent level={3} text={'Contenidos'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <Tabs activeKey={currentActiveKey} centered size={'small'} onChange={handleChangeTab}>
          <Tabs.TabPane tab={'¿Quiénes somos?'} key={routes.contents.aboutUs}>
            <AboutUsContents/>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Nuestro equipo'} key={routes.contents.ourTeam}>
            <OurTeamContent/>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Vacantes'} key={routes.contents.positions}>
            <PositionContent/>
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  )
}

export default Contents;