// Constants
import {ApplicationsReducerType} from '../../../types/storeTypes';
import {applicationTypes} from '../types';

const initialState = {
  applicationParams: {},
  applicationDetail: {
    application: {
      applicant: {}
    },
    events: [],
    files: [],
    formation: [],
    languages: [],
    othersApplication: [],
    sports: [],
  }
}

const applicationsReducer = (state = initialState, action: ApplicationsReducerType) => {
  switch (action.type) {
    case applicationTypes.RESET_APPLICATION_VALUES:
      return {
        ...state,
        applicationDetail: initialState.applicationDetail
      };
    case applicationTypes.GET_APPLICATION:
      return {
        ...state,
        applicationDetail: action.applicationDetail
      };
    case applicationTypes.GET_APPLICATIONS_PARAMS:
      return {
        ...state,
        applicationParams: action.applicationParams
      };
    default:
      return state;
  }
};

export default applicationsReducer;
