// Libraries
import React from 'react';
import { Layout } from 'antd';

// Styles
import 'antd/dist/antd.css';
import './styles/app.css';
import 'react-quill/dist/quill.snow.css';
import 'moment/locale/es-mx';

// Store
import {useSelector} from 'react-redux';
import * as sessionSelectors from './store/redux/selectors/sessionSelectors';
import * as generalSelectors from './store/redux/selectors/generalSelectors';

// Components
import Router from './components/general/Router';
import Navbar from './components/general/Navbar';
import Loading from './components/general/Loading';

function App() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const isLoading = useSelector(generalSelectors.isLoadingSelector);
  
  return (
    <Layout hasSider className={'flex'}>
      {isLoading && <Loading />}
      {isLoggedIn && <Navbar />}

      <Router />
    </Layout>
  );
}

export default App;
