import request from './requestWrapper';

// Types
import { ApplicationEventsType } from '../types/applicationsTypes';

// #region POST

function create(data: ApplicationEventsType) {
  return request({
    url: 'eventsApplications/create',
    method: 'POST',
    data: data
  });
}

// #endregion

export default {
  create
}