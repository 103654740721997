// Libraries
import React, {FunctionComponent} from 'react';
import {Checkbox, Col, Row, Form} from 'antd';
import PropTypes from 'prop-types';
import {SearchOutlined} from '@ant-design/icons';

// Store
import {useSelector} from 'react-redux';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Components and Subcomponents
import SelectComponent from '../../subComponents/SelectComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';

// Functions and Constants
import {userIsAdmin} from '../../../utils/functions';
import {FilterType} from '../../../types/sharedTypes';


type PositionFiltersType = {
  onChangeSubscriptions: (checked: boolean) => void;
  onChangeFilters: (filters: FilterType) => void;
}
const PositionFilters: FunctionComponent<PositionFiltersType> = (
  {
    onChangeSubscriptions, onChangeFilters
  }) => {
  const [form] = Form.useForm();
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const filterVisible = userIsAdmin();

  const onSubmit = (values: FilterType) => {
    onChangeFilters(values);
  };

  return (
    <Row gutter={[8, 8]}>
        <Col lg={16} md={14} xs={24}>
          <Form form={form} layout="vertical" autoComplete="off" onFinish={onSubmit}>
            <Col lg={11} md={11} xs={24} className={'w-full inline-block'}>
                <FormItemComponent name={'positionName'} label={'Nombre de la vacante'} required={false}
                                  child={
                                    <InputComponent />
                                  } />
            </Col>
            {filterVisible &&
              <Col lg={11} md={11} xs={24} className={'w-full inline-block'}>
                <FormItemComponent name={'idTeam'} label={'Equipo'} required={false}
                                  child={
                                    <SelectComponent allowClear={true}
                                                      className={'w-full'} list={positionParams!.teamOptions} />
                                  } />
              </Col>
            }
            <Col lg={2} md={2} xs={24} className={'w-full inline-block align-bottom pb-5 text-right md:text-left'}>
              <ButtonComponent text={''} icon={<SearchOutlined />}
                                className={'bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                          'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none text-white'}
                                onClick={() => form.submit()}/>
            </Col>
          </Form>
        </Col>
      
      <Col lg={8} md={10} xs={24} 
          className={'text-right my-auto'}>
        <Checkbox className={'h-full'} onChange={({target}) => onChangeSubscriptions(target.checked)}>
          Únicamente suscripciones
        </Checkbox>
      </Col>
    </Row>
  );
}

PositionFilters.propTypes = {
  onChangeSubscriptions: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};
PositionFilters.defaultProps = {
  onChangeSubscriptions: () => {
    return;
  },
  onChangeFilters: () => {
    return;
  }
};

export default PositionFilters;