import {message} from 'antd';

export const hoverableCardClassName = () => {
  return '[&>div]:p-0 rounded shadow shadow-grey-dkt-400 group before:absolute before:-top-px before:h-px ' +
    'before:w-0 before:bg-blue-dkt-600 before:transition-all before:ease-linear hover:before:w-full after:absolute ' +
    'after:h-px after:w-0 after:bg-blue-dkt-600 after:transition-all after:ease-linear hover:after:w-full';
}

export const hoverableCardContentClassName = () => {
  return 'p-4 before:absolute before:top-0 before:-left-px before:h-0 before:w-px before:bg-blue-dkt-600 ' +
    'before:transition-all before:ease-linear group-hover:before:h-full after:absolute after:top-0 after:-right-px ' +
    'after:h-0 after:w-px after:bg-blue-dkt-600 after:transition-all after:ease-linear group-hover:after:h-full';
}

export const uploadProps = (acceptFormat: string, callback: (file: File) => void) => {
  return {
    multiple: false,
    showUploadList: false,
    accept: acceptFormat,
    customRequest({ file, onSuccess, onError }: any) {
      let error = false;

      if(file.type === '' || !acceptFormat.includes(file.type)){
        message.error({ content: `El archivo "${file.name}" no está permitido.` });
        error = true;
      } else if(file.size/1024/1024 > 5) {
        message.error({ content: `El archivo "${file.name}" sobrepasa el tamaño permitido (5MB).` });
        error = true;
      }

      if (error) {
        setTimeout(() => { onError('error'); }, 0);
        return;
      }

      setTimeout(() => { onSuccess('ok'); }, 0);
      callback(file);
    },
  };
}