// Constants
import {SettingReducerType} from '../../../types/storeTypes';
import {settingTypes} from '../types';

const initialState = {
  categories: [],
  places: [],
  teamParams: [],
  teams: [],
  workingHours: [],
  formations: [],
  referrals: [],
  languages: [],
  sports: [],
  eventParams: [],
  events: [],
  settingDetail: {
    name: '',
    latitude: '',
    longitude: '',
    isActive: false,
  }
};

const positionsReducer = (state = initialState, action: SettingReducerType) => {
  switch (action.type) {
    case settingTypes.GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case settingTypes.GET_ALL_PLACES:
      return {
        ...state,
        places: action.places
      };
    case settingTypes.GET_TEAM_PARAMS:
      return {
        ...state,
        teamParams: action.teamParams
      };
    case settingTypes.GET_ALL_TEAMS:
      return {
        ...state,
        teams: action.teams
      };
    case settingTypes.GET_ALL_WORKING_HOURS:
      return {
        ...state,
        workingHours: action.workingHours
      };
    case settingTypes.GET_ALL_FORMATIONS:
      return {
        ...state,
        formations: action.formations
      };
    case settingTypes.GET_ALL_LANGUAGES:
      return {
        ...state,
        languages: action.languages
      };
    case settingTypes.GET_ALL_REFERRALS:
      return {
        ...state,
        referrals: action.referrals
      };
    case settingTypes.GET_ALL_SPORTS:
      return {
        ...state,
        sports: action.sports
      };
    case settingTypes.GET_EVENT_PARAMS:
      return {
        ...state,
        eventParams: action.eventParams
      };
    case settingTypes.GET_ALL_EVENTS:
      return {
        ...state,
        events: action.events
      };
    case settingTypes.GET_SETTING_DETAIL:
      return {
        ...state,
        settingDetail: action.settingDetail
      };
    default:
      return state;
  }
};

export default positionsReducer;
