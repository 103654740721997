// Libraries
import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import { useSelector } from 'react-redux';

// Store
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Types
import { ApplicationFiltersType } from '../../../types/applicationsTypes';

// Components and Subcomponents
import SelectComponent from '../../subComponents/SelectComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';


type ApplicantDetailFiltersType = {
  onChangeFilters: (applicationFilters: ApplicationFiltersType) => void;
};
const ApplicantDetailFilters: FunctionComponent<ApplicantDetailFiltersType> = ({
  onChangeFilters,
}) => {
  const [form] = Form.useForm();
  const positionParams = useSelector(positionSelectors.positionParamsSelector);

  const handleSubmit = (_value: ApplicationFiltersType, allValues: ApplicationFiltersType) => {
    onChangeFilters(allValues)
  }
  
  return (
    <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleSubmit}> 
      <Row gutter={[10, 10]}>  
        <Col xl={4} lg={6} md={8} sm={12} xs={24}>
          <FormItemComponent name={'idReferred'} required={false}
                             child={
                               <SelectComponent allowClear={true} list={positionParams!.referredChannelOptions}
                                                placeHolder={'Canal'} />
                             } />
        </Col>
        <Col xl={4} lg={6} md={8} sm={12} xs={24}>
          <FormItemComponent name={'idSports'} required={false} 
                             child={
                               <SelectComponent allowClear={true} list={positionParams!.sportOptions}
                                                placeHolder={'Deporte'} />
                             } />
        </Col>
        <Col xl={4} lg={6} md={8} sm={12} xs={24}>
          <FormItemComponent name={'idEvent'} required={false} 
                             child={
                               <SelectComponent allowClear={true} list={positionParams!.eventOptions}
                                                placeHolder={'Evento'} />
                             } />
        </Col>
      </Row>
    </Form> 
  );
};

ApplicantDetailFilters.propTypes = {
  onChangeFilters: PropTypes.func.isRequired,
};
ApplicantDetailFilters.defaultProps = {
  onChangeFilters: () => {
    return;
  },
};

export default ApplicantDetailFilters;
