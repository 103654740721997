import React, {useEffect, useState} from 'react';
import {Row, Col, Space, BackTop} from 'antd';
import {UploadOutlined, PlusOutlined, RedoOutlined} from '@ant-design/icons';
import {useAppDispatch} from '../../store/store';

// Types
import {GetPositionsType} from '../../types/positionTypes';
import {FilterType, PaginationType} from '../../types/sharedTypes';

// Store
import {getPositionParams, getAllPositions} from '../../store/redux/actions/positionActions';
import {getAllPositionsSubscribed} from '../../store/redux/actions/subscriptionActions';

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import PositionsList from './PositionList';
import UploadCollaboratorModal from './UploadCollaboratorModal';
import PositionFilters from './filters/PositionFilters';

// Functions and Constants
import {redirectWindow, screenSizeValidator, userIsAdmin} from '../../utils/functions';
import {routes} from '../../utils/routes';


const Positions = () => {
  const [paramData, setParamData] = useState<GetPositionsType>({
    paginationVm:{
      page: 1,
      size: 5
    },
    filterVm:{}
  });
  const [collaboratorModalVisible, setCollaboratorModal] = useState(false);
  const [onlySubscriptions, setOnlySubscriptions] = useState(false);
  const dispatch = useAppDispatch();
  const isMobile = screenSizeValidator('(max-width: 550px)');

  useEffect(() => {
    if(onlySubscriptions){
      dispatch(getPositionParams(getAllPositionsSubscribed(paramData)));
    } else {
      dispatch(getPositionParams(getAllPositions(paramData)));
    }
  }, [paramData, onlySubscriptions]);

  const handleChangeFilters = (filters: FilterType) => {
    setParamData({...paramData, filterVm: filters});
  };
  const handleLoadMore = () => {
    const paginationVm: PaginationType = {
      size: paramData.paginationVm.size + 5,
      page: 1
    }
    setParamData({...paramData, paginationVm });
  };
  const handleRedirectNewPosition = () => {
    redirectWindow(routes.positions.new);
  };
  const handleCollaboratorModalVisible = (visible: boolean) => {
    setCollaboratorModal(visible);
  }

  return (
    <Row>
      <Col md={5} sm={24} xs={24}>
        <TitleComponent level={3} text={'Vacantes'} className={'font-semibold text-gray-900'} />
      </Col>
      {userIsAdmin() && 
        <Col md={19} sm={24} xs={24} className={'text-right'}>
          <Space size={'small'}>
            <ButtonComponent text={isMobile ? '' : 'Cargar ex-colaboradores'}
                            icon={<UploadOutlined />}
                            className={`${isMobile ? 'w-20' : 'w-fit'} ` +
                              'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-orange-dkt-300 text-white '}
                            onClick={() => handleCollaboratorModalVisible(true)} />      
            <ButtonComponent text={isMobile ? '' : 'Nueva vacante'}
                            icon={<PlusOutlined />}
                            className={`${isMobile ? 'w-20' : 'w-fit'} ` +
                              'bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none text-white'}
                            onClick={handleRedirectNewPosition} />
          </Space>
        </Col>
      }
      <Col md={24} xs={24} className={'mt-5'}>
        <PositionFilters onChangeSubscriptions={setOnlySubscriptions}
                         onChangeFilters={handleChangeFilters}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <PositionsList onlySubscriptions={onlySubscriptions} />
      </Col>

      <Col md={24} xs={24} className={'text-center pt-5'}>
        <ButtonComponent text={'Ver más vacantes'} icon={<RedoOutlined />}
                         className={'w-60 h-9 justify-center border border-transparent rounded-md ' +
                           'shadow-sm text-sm font-medium text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300'}
                         onClick={handleLoadMore} />
      </Col>

      <BackTop className={'[&>div]:bg-grey-dkt-600 bottom-14 right-14'} />

      {collaboratorModalVisible &&
        <UploadCollaboratorModal onToggleModal={handleCollaboratorModalVisible} />
      }
    </Row>
  );
}

export default Positions;