import request from './requestWrapper';


// #region GET

function getDashboard() {
  return request({
    url: 'dashboard/',
    method: 'GET',
  });
}

// #endregion

export default {
  getDashboard
}