// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import dashboardServices from '../../../api/dashboardServices';

// Types
import {dashboardTypes} from '../types';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


// #region GET

export const getDashboard = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dashboardServices.getDashboard()
    .then((response) => {
      dispatch({
        type: dashboardTypes.GET_DASHBOARD,
        dashboardInfo: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion