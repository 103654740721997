import {StoreType} from '../../../types/storeTypes';

export const applicationsDetailSelector = (state: StoreType) => {
  return state.applicationsReducer.applicationDetail;
}

export const applicationParamsSelector = (state: StoreType) => {
  return state.applicationsReducer.applicationParams;
}

export const getAllApplicationsSelector = (state: StoreType) => {
  return state.applicationsReducer.applications;
}