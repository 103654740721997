import request from './requestWrapper';

// Types
import {SettingType} from '../types/settingsTypes';


// #region GET

function getAllCategories() {
  return request({
    url: 'categories/getAll',
    method: 'GET',
  });
}
function getAllPlaces() {
  return request({
    url: 'places/getAll',
    method: 'GET',
  });
}
function getTeamParams() {
  return request({
    url: 'teams/getParams',
    method: 'GET',
  });
}
function getAllTeams() {
  return request({
    url: 'teams/getAll',
    method: 'GET',
  });
}
function getAllWorkingHours() {
  return request({
    url: 'workingHours/getAll',
    method: 'GET',
  });
}
function getAllFormations() {
  return request({
    url: 'formations/getAll',
    method: 'GET',
  });
}
function getAllLanguages() {
  return request({
    url: 'languages/getAll',
    method: 'GET',
  });
}
function getAllReferrals() {
  return request({
    url: 'referred/getAll',
    method: 'GET',
  });
}
function getAllSports() {
  return request({
    url: 'sports/getAll',
    method: 'GET',
  });
}
function getEventParams() {
  return request({
    url: 'events/getParams',
    method: 'GET',
  });
}
function getAllEvents() {
  return request({
    url: 'events/getAll',
    method: 'GET'
  });
}

function getCategoryById(categoryId: string) {
  return request({
    url: `categories/getById/${categoryId}`,
    method: 'GET',
  });
}
function getPlaceById(placeId: string) {
  return request({
    url: `places/getById/${placeId}`,
    method: 'GET',
  });
}
function getTeamById(teamId: string) {
  return request({
    url: `teams/getById/${teamId}`,
    method: 'GET',
  });
}
function getWorkingHourById(workingHourId: string) {
  return request({
    url: `workingHours/getById/${workingHourId}`,
    method: 'GET',
  });
}
function getFormationById(formationId: string) {
  return request({
    url: `formations/getById/${formationId}`,
    method: 'GET',
  });
}
function getLanguageById(languageId: string) {
  return request({
    url: `languages/getById/${languageId}`,
    method: 'GET',
  });
}
function getReferredById(referredId: string) {
  return request({
    url: `referred/getById/${referredId}`,
    method: 'GET',
  });
}
function getSportById(sportId: string) {
  return request({
    url: `sports/getById/${sportId}`,
    method: 'GET',
  });
}
function getEventById(eventId: string) {
  return request({
    url: `events/getById/${eventId}`,
    method: 'GET',
  });
}

// #endregion

// #region POST

function addCategoryImage(data: FormData) {
  return request({
    url: 'categories/addImage',
    method: 'POST',
    data: data
  });
}
function createCategory(data: SettingType) {
  return request({
    url: 'categories/create',
    method: 'POST',
    data: data
  });
}
function createPlace(data: SettingType) {
  return request({
    url: 'places/create',
    method: 'POST',
    data: data
  });
}
function createTeam(data: SettingType) {
  return request({
    url: 'teams/create',
    method: 'POST',
    data: data
  });
}
function createWorkingHour(data: SettingType) {
  return request({
    url: 'workingHours/create',
    method: 'POST',
    data: data
  });
}
function createFormation(data: SettingType) {
  return request({
    url: 'formations/create',
    method: 'POST',
    data: data
  });
}
function createLanguage(data: SettingType) {
  return request({
    url: 'languages/create',
    method: 'POST',
    data: data
  });
}
function createReferred(data: SettingType) {
  return request({
    url: 'referred/create',
    method: 'POST',
    data: data
  });
}
function createSport(data: SettingType) {
  return request({
    url: 'sports/create',
    method: 'POST',
    data: data
  });
}
function createEvent(data: SettingType) {
  return request({
    url: 'events/create',
    method: 'POST',
    data: data
  });
}

// #endregion

// #region PUT

function updateCategory(data: SettingType) {
  return request({
    url: 'categories/update',
    method: 'PUT',
    data: data
  });
}
function updatePlace(data: SettingType) {
  return request({
    url: 'places/update',
    method: 'PUT',
    data: data
  });
}
function updateTeam(data: SettingType) {
  return request({
    url: 'teams/update',
    method: 'PUT',
    data: data
  });
}
function updateWorkingHour(data: SettingType) {
  return request({
    url: 'workingHours/update',
    method: 'PUT',
    data: data
  });
}
function updateFormation(data: SettingType) {
  return request({
    url: 'formations/update',
    method: 'PUT',
    data: data
  });
}
function updateLanguage(data: SettingType) {
  return request({
    url: 'languages/update',
    method: 'PUT',
    data: data
  });
}
function updateReferred(data: SettingType) {
  return request({
    url: 'referred/update',
    method: 'PUT',
    data: data
  });
}
function updateSport(data: SettingType) {
  return request({
    url: 'sports/update',
    method: 'PUT',
    data: data
  });
}
function updateEvent(data: SettingType) {
  return request({
    url: 'events/update',
    method: 'PUT',
    data: data
  });
}

// #endregion

export default {
  getAllCategories, getAllPlaces, getTeamParams, getAllTeams, getAllWorkingHours, getAllFormations, getAllReferrals,
  getAllLanguages, getAllSports, getEventParams, getAllEvents, getCategoryById, getPlaceById, getTeamById, getWorkingHourById, 
  getFormationById, getLanguageById, getReferredById, getSportById, getEventById, addCategoryImage, createCategory, createPlace,
  createTeam, createWorkingHour, createFormation, createLanguage, createReferred, createSport, createEvent, updateCategory, updatePlace,
  updateTeam, updateWorkingHour, updateFormation, updateReferred, updateLanguage, updateSport, updateEvent
}