// Libraries
import React, {FunctionComponent} from 'react';
import {List, Row, Col, Tooltip, Popconfirm, Space} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';
import { EditOutlined, DeleteOutlined, ToolOutlined, EnvironmentOutlined, TeamOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import { deleteUser } from '../../store/redux/actions/userActions';
import * as userSelector from '../../store/redux/selectors/userSelectors';

// Types
import {UserType} from '../../types/userTypes';
import { ParamType } from '../../types/sharedTypes';

// Functions
import { screenSizeValidator } from '../../utils/functions';


type UserListType = {
  onToggleModal: (visible: boolean, userToEdit?: UserType) => void;
}
const UserList: FunctionComponent<UserListType> = (
  {
    onToggleModal,
  }) => {
    const dispatch = useAppDispatch();
    const userParams = useSelector(userSelector.userParamsSelector);
    const userList = useSelector(userSelector.allUsersSelector);
    const isMD = screenSizeValidator('(max-width: 920px)');
    const roleTypes = userParams.roleTypes;
    const teamOptions = userParams.teamOptions;
    const placeOptions = userParams.placeOptions;

    return (
      <List dataSource={userList} grid={{ gutter: 16, column: isMD ? 1 : 2 }}
            renderItem={(user) => (
              <List.Item>
                <Row className={'flex'}>
                  <Col md={20} sm={20} xs={24}>
                    <p className={'font-medium'}>{user.email}</p>
                    <p>
                      <ToolOutlined title={'Rol'} className={'mr-2'} />
                      {roleTypes.find((param: ParamType) => param.id === user.role)?.value}
                    </p>
                    <p>
                      <EnvironmentOutlined title={'Tienda'} className={'mr-2'} />
                      {placeOptions.find((place) => place.id === user.idPlace)?.value}
                    </p>
                    <p>
                      <TeamOutlined title={'Equipo'} className={'mr-2'} />
                      {teamOptions.find((team) => team.id === user.idTeam)?.value}
                    </p>
                  </Col>
                  <Col md={4} sm={4} xs={0} className={'hidden sm:block md:block my-auto sm:text-right'}>
                    <Tooltip title={'Editar'}>
                      <EditOutlined className={'inline-block mx-2 text-blue-dkt-400'}
                                    onClick={() => onToggleModal(true, user)} />
                    </Tooltip>
                    <Tooltip title={'Eliminar'}>
                      <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                  onConfirm={() => dispatch(deleteUser(user.idUser!))}>
                        <DeleteOutlined className={'inline-block mx-2 text-red-dkt-400'} />
                      </Popconfirm>
                    </Tooltip>
                  </Col>
                  <Col md={0} sm={0} xs={24} className={'block sm:hidden md:hidden mx-auto sm:my-auto md:my-auto sm:text-right text-center'}>
                    <Space size={'middle'}>
                      <p className={'text-blue-dkt-400 cursor-pointer'} onClick={() => onToggleModal(true, user)}>
                        <EditOutlined /> Editar
                      </p>
                      <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                  onConfirm={() => dispatch(deleteUser(user.idUser!))}>
                        <p className={'text-red-dkt-400 cursor-pointer'}>
                          <DeleteOutlined /> Eliminar
                        </p>
                      </Popconfirm>
                    </Space>
                  </Col>
                </Row>
              </List.Item>
            )}
      />
  );
}

UserList.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
UserList.defaultProps = {
  onToggleModal: () => {
    return null;
  },
};

export default UserList;