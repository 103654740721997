import request from './requestWrapper';

// #region GET

function getAllApplicationInformationById(idApplication: string) {
  return request({
    url: `applications/getAllApplicationInformationById/${idApplication}`,
    method: 'GET',
  });
}

function getParams() {
  return request({
    url: 'applications/getParams',
    method: 'GET'
  });
}

// #endregion

export default {
  getAllApplicationInformationById, getParams
}