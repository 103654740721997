import React from 'react';

type CustomIconsType = {
  width: number,
  height: number,
  fill: string,
  className?: string
};

export const PostIcon = ({ width, height, fill, className }: CustomIconsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision"
       imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 500 511.78"
       height={`${height}px`} width={`${width}px`} fill={fill} className={className}>
    <path d="M497.71 66 433.95 2.23c-5.43-5.43-14.03.18-16.61 4.95-.43.82.34 1.55-.36 7.07-.87 6.84-2.42 14.44-4.92
    20.46l-28.35 28.36c-11.32 11.32-27.51 6.29-41.32-.58-2.91-1.44-6.66-4.64-9.88-1.41L320.5 73.09c-1.76 1.74-1.76
    4.61 0 6.37l100.77 100.78c1.76 1.76 4.63 1.76 6.38 0l12.01-12.01c3.19-3.19-.88-7.33-2.56-10.79-6.14-12.72-11.64-29.66-1.41-39.9l29.61-29.6c5.82-1.82
    13.34-3.53 20.51-4.66 5.58-.87 6.27-.19 7.07-.71 4.88-3.15 10.29-11.11 4.83-16.57zM129.54 98.24l-83.31
    88.01h83.31V98.24zM0 202.93c0-1.14.14-2.25.4-3.32.12-3.23 1.36-6.44 3.76-8.98L130.89 56.75c2.36-4.27 6.9-7.16
    12.12-7.16h156.91l-1.47 1.48a35.576 35.576 0 0 0-10.44 25.2l.02 1.02h-130.8v122.8c0 7.65-6.2 13.85-13.85
    13.85H27.7v270.14h399.45V212.62c8.21-.61 16.25-4.07 22.53-10.35l5.16-5.16v287.13c0 7.59-3.09 14.48-8.07 19.46-5.03
    4.97-11.92 8.08-19.46 8.08H27.53c-7.56 0-14.45-3.11-19.44-8.1C3.11 498.7 0 491.8 0 484.24V202.93zm331.02 80.9c7.69 0
    13.92 6.17 13.92 13.78 0 7.61-6.23 13.78-13.92 13.78h-207.2c-7.68 0-13.92-6.17-13.92-13.78 0-7.61 6.24-13.78
    13.92-13.78h207.2zm0 92.95c7.69 0 13.92 6.18 13.92 13.78 0 7.61-6.23 13.78-13.92 13.78h-207.2c-7.68
    0-13.92-6.17-13.92-13.78 0-7.6 6.24-13.78 13.92-13.78h207.2zm39.09-223.86-22.95-22.95-73.66 93.14 3.46 3.47 93.15-73.66z"/>
  </svg>
);
export const ReviewIcon = ({ width, height, fill, className }: CustomIconsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.699 122.879" enableBackground="new 0 0 121.699 122.879"
       height={`${height}px`} width={`${width}px`} fill={fill} className={className}>
    <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M45.065,57.1l4.659,13.702l2.343-8.131l-1.148-1.257
      c-0.518-0.755-0.631-1.416-0.344-1.984c0.622-1.23,1.909-1.001,3.112-1.001c1.26,0,2.816-0.239,3.209,1.338
      c0.132,0.527-0.034,1.08-0.403,1.647l-1.148,1.257l2.343,8.131L61.906,57.1c3.043,2.738,13.754,3.288,17.105,5.157
      c1.061,0.591,2.018,1.344,2.787,2.361c1.168,1.542,1.885,3.559,2.082,6.116l0.699,3.097c-0.17,1.811-1.197,2.854-3.219,3.008
      H25.609c-2.021-0.154-3.048-1.197-3.218-3.008l0.699-3.097c0.196-2.558,0.912-4.573,2.082-6.116c0.77-1.018,1.727-1.77,2.788-2.361
      C31.311,60.388,42.021,59.838,45.065,57.1L45.065,57.1L45.065,57.1z M65.447,44.052v-4.185c0.189-0.809,0.545-1.049,0.945-1.123
      C67.473,38.576,67.355,43.802,65.447,44.052L65.447,44.052z M39.042,29.827v-2.678c0.387-3.203,1.878-4.489,4.326-4.121
      c2.33-2.436,5.618-3.795,10.146-3.759c5.043-0.1,9.343,1.077,12.464,4.274c1.641,1.635,2.34,4.167,1.916,7.77
      c0.088,3.533,0.477,5.065-0.668,5.687c-0.008,0.098-0.01,0.191-0.018,0.291l-0.791,0.494c2.521-0.188,2.037,6.872-0.857,7.297
      c-0.064,0.009-0.127,0.01-0.189,0.004v0.218c0,2.814-1.293,5.208-3.209,7.209c-3.714,3.879-9.816,5.808-15.113,1.416
      c-0.921-0.764-1.993-1.521-2.835-2.703c0.009-0.009,0.019-0.018,0.028-0.026c-1.66-1.563-2.092-2.191-2.572-4.497
      c-0.011-0.053-0.017-0.106-0.017-0.157h-0.001V45.08c-0.077,0.013-0.158,0.014-0.24,0.002c-2.948-0.432-3.408-7.779-0.692-7.274
      c0.348,0.064,0.667,0.245,0.932,0.547v-0.596l-0.594-0.333c-0.072-0.481-0.083-0.972-0.048-1.467
      C39.945,34.181,39.119,31.208,39.042,29.827L39.042,29.827z M64.213,37.036c-0.699-1.956-2.033-3.424-3.712-4.451
      c-1.411-0.611-2.646-1.128-3.819-1.5c-2.845-0.634-5.974-0.451-8.556,0.413c-0.687,0.27-1.417,0.596-2.201,0.984
      c-1.649,0.967-2.83,2.305-3.179,3.954v10.11c0.588,2.819,0.962,2.706,2.769,4.474l3.133,3.067c0.566,0.467,1.203,0.741,1.881,0.9
      c2.318,0.544,7.192,0.328,9.021-1.592c2.726-2.646,4.853-3.861,4.663-8.091V37.036L64.213,37.036z M41.523,44.052v-4.185
      c-0.19-0.808-0.543-1.048-0.943-1.123C39.502,38.578,39.604,43.799,41.523,44.052L41.523,44.052z M53.613,0
      c14.79,0,28.202,6.018,37.918,15.694c9.715,9.716,15.693,23.089,15.693,37.918c0,10.817-3.225,20.926-8.732,29.343l23.207,25.292
      l-16.008,14.632L83.311,98.256c-8.498,5.664-18.725,8.969-29.698,8.969c-14.79,0-28.203-6.019-37.918-15.694
      C5.979,81.814,0,68.44,0,53.612c0-14.79,6.018-28.202,15.695-37.918C25.41,5.979,38.784,0,53.613,0L53.613,0z
      M87.283,19.942 C78.668,11.328,66.75,5.979,53.613,5.979c-13.138,0-25.056,5.35-33.67,13.963c-8.614,8.614-13.964,20.532-13.964,33.67
      c0,13.138,5.35,25.056,13.964,33.67c8.614,8.614,20.532,13.964,33.67,13.964c13.137,0,25.055-5.35,33.67-13.964
      c8.613-8.614,13.963-20.532,13.963-33.67C101.246,40.474,95.896,28.556,87.283,19.942L87.283,19.942L87.283,19.942z"/>
    </g>
  </svg>
);
export const BestApplicantIcon = ({ width, height, fill, className }: CustomIconsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 91.39"
       height={`${height}px`} width={`${width}px`} fill={fill} className={className}>
    <path d="M65,8l8.58.64L67,14.16l2,8.36L61.75,18l-7.31,4.52,2.05-8.36L49.91,8.6,58.49,8l3.25-8L65,8ZM29.51,91.39c.19-5.84-.14-5.93,6.69-8.48,4-1.51,9.13-3.41,12.81-5.7,4.33,4,7.1,8.59,7.2,14.18ZM53,71.7c.19-1.48-4.19-7.09-5-9.79-1.71-2.71-2.32-6.43-.45-9.3.74-1.14.42-2.09.42-3.65,0-15.63,27.39-15.65,27.39,0,0,2-.45,2.39.62,3.94,1.8,2.61.87,6.6-.65,9-1,2.83-5.54,8.18-5.23,9.79.27,8-17.18,7.75-17.12,0ZM94,91.39c-.19-5.84.14-5.93-6.69-8.48-4-1.51-9.13-3.41-12.81-5.7-4.33,4-7.1,8.59-7.2,14.18Zm28.9-52.81-5.27,6.81,3.74,7.74-8.09-2.91-6.21,6,.26-8.6-7.58-4.07L108,41.1l1.53-8.47,4.83,7.11,8.53-1.16Zm-23.4-27-1.15,8.53,7.11,4.84L97,26.44,94.56,34.7,90.5,27.12l-8.6.26,5.95-6.21-2.92-8.1,7.75,3.74,6.8-5.28ZM0,38.59l8.53,1.16,4.84-7.11,1.54,8.46,8.26,2.41-7.58,4.07.26,8.6-6.22-6-8.1,2.91,3.74-7.75L0,38.59ZM24,11.53l6.8,5.27,7.75-3.74-2.9,8.09,6,6.21L33,27.1,29,34.68l-2.41-8.26-8.48-1.53L25.17,20,24,11.53Z"/>
  </svg>
);