// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Col, List, Card, Space, Empty, Tag} from 'antd';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';
import * as applicationSelectors from '../../../store/redux/selectors/applicationSelectors';
import { getAll } from '../../../store/redux/actions/applicationActions';

// Types
import {ApplicationFiltersType, GetApplicationsType} from '../../../types/applicationsTypes';

// Functions and Constants
import {getDateDifference, screenSizeValidator} from '../../../utils/functions';
import {hoverableCardClassName, hoverableCardContentClassName} from '../../../utils/componentsUtils';

// Components
import ApplicantDetailFilters from '../filters/ApplicantDetailFilters';
import ApplicantDetailModal from './ApplicantDetailModal';
import { PaginationType } from '../../../types/sharedTypes';


const ApplicantList = () => {
  const dispatch = useAppDispatch();
  const [applicantModalVisible, setApplicantModalVisible] = useState(false);
  const [idApplications, setIdApplications] = useState('');
  const applications = useSelector(applicationSelectors.getAllApplicationsSelector);

  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const positionDetail = useSelector(positionSelectors.positionDetailSelector);
  const isXS = screenSizeValidator('(max-width: 575px)');
  const isXL = screenSizeValidator('(min-width: 1200px)');
  const [paramData, setParamData] = useState<GetApplicationsType>({
    paginationVm:{
      page: 1,
      size: isXS ? 3 : isXL ? 8 : 6
    },
    filterVm:{
      idPosition: positionDetail?.idPosition
    }
  });

  useEffect(() => {
    if(paramData.filterVm.idPosition)
      dispatch(getAll(paramData));
  }, [paramData]);

  useEffect(() => {
    const filters: ApplicationFiltersType = {
      idPosition: positionDetail?.idPosition
    }

    setParamData({...paramData, filterVm:filters});
    dispatch(getAll({...paramData, filterVm:filters}));
  }, [positionDetail]);

  useEffect(() => {
    const paginationVm: PaginationType = {
      size: isXS ? 3 : isXL ? 8 : 6,
      page: 1
    };

    setParamData({...paramData, paginationVm });
  }, [isXL, isXS]);

  const onToggleModal = (isApplicantModalVisible: boolean, idApplications?: string) => {
    setApplicantModalVisible(isApplicantModalVisible);
    setIdApplications(idApplications ?? '');
  }
  
  const handleApplicantFilters = (filters: ApplicationFiltersType) => {
    filters.idPosition = positionDetail?.idPosition;
    const pagination = {
      page: 1,
      size: paramData.paginationVm.size
    };

    setParamData({paginationVm: pagination, filterVm: filters});
  }
  const handlePagination = (page: number, pageSize: number) => {
    const paginationVm: PaginationType = {
      size: pageSize,
      page: page
    };

    setParamData({...paramData, paginationVm });
  };

  return (
    <Col md={24} xs={24}>
      <Col md={24} xs={24} className={'mb-4'}>
        <ApplicantDetailFilters onChangeFilters={handleApplicantFilters} />
      </Col>    
    
    {applications?.rows.length > 0
      ?
      <Col md={24} xs={24}>
        <List dataSource={applications.rows}
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4 }}
              pagination={{
                total: applications.count,
                size: 'small',
                current: paramData.paginationVm.page,
                pageSize: isXS ? 3 : isXL ? 8 : 6,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (page, pageSize) => {handlePagination(page, pageSize)},
              }}
              
              renderItem={(application) => (
                <List.Item>
                  <Card hoverable={true} className={hoverableCardClassName()}
                        onClick={() => onToggleModal(true, application.idApplications)}>
                    <Row className={hoverableCardContentClassName()}>
                      <Space direction={'vertical'} size={'middle'} className={'w-full'}>   
                        <Col md={24} xs={24} className={'text-right'}>
                          <Tag color="blue" className={'rounded'}>
                            {positionParams?.placeOptions.find(placeOption => 
                              placeOption.id === application.placePosition?.idPlace)?.value
                            }
                          </Tag>
                        </Col>                   
                        <Col md={24} xs={24} className={'text-center text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                          <b className={'text-grey-dkt-700 leading-none'} title={'Nombre Apellido'}>
                            {application.applicant!.firstName} {application.applicant!.lastName}
                          </b>
                          
                          <p className={'m-0 text-grey-dkt-400 leading-none italic'}>
                            Se postuló hace {getDateDifference(application.timeStamp)}
                          </p>
                        </Col>
                        <Col md={24} xs={24} className={'text-xs text-grey-dkt-600 text-center bg-grey-dkt-50 p-4 rounded'}>
                          <Col md={24} xs={24} className={'text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                            <MailOutlined className={'mr-2'} />
                            <span title={application.applicant!.email}>{application.applicant!.email}</span>
                          </Col>
                          <Col md={24} xs={24} className={'text-ellipsis whitespace-nowrap w-full overflow-hidden'}>
                            <PhoneOutlined className={'mr-2'} />
                            <span title={application.applicant!.phone}>{application.applicant!.phone}</span>
                          </Col>
                        </Col>
                      </Space>
                    </Row>
                  </Card>
                </List.Item>
              )}
        />

        {applicantModalVisible &&
          <ApplicantDetailModal idApplications={idApplications} onToggleModal={setApplicantModalVisible} />
        }
      </Col>
      :
      <Empty description={'No hay candidatos'} />
    }
    </Col>
  );
}

export default ApplicantList;